<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container fluid>
    <!-- Loop through each resource type and create a card -->
    <v-row class="ml-6 mr-6">
       <!-- Conference Resource -->
       <v-col cols="12" v-if="conference">
        <v-card class="pa-3 mb-3" outlined>
          <v-card-title>
            <v-icon large left color="primary">mdi-video-wireless-outline</v-icon>
            IAO Conference
          </v-card-title>
          <v-card-subtitle>{{ conference.description }}</v-card-subtitle>
          <v-list>
            <v-list-item :href="conference.weblink" target="_blank">
              <v-list-item-content>Main Conference Link</v-list-item-content>
              <v-list-item-icon>
                <v-icon color="error">mdi-open-in-new</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :href="conference.conference_recording_summary_next_steps_url" target="_blank" v-if="conference.conference_recording_summary_next_steps_url">
              <v-list-item-icon>
                <v-icon color="primary">mdi-step-forward</v-icon>
              </v-list-item-icon>
              <v-list-item-content>BAO Conference Summary & Next Steps</v-list-item-content>
            </v-list-item>
            <v-list-item :href="conference.conference_recording_transcript_url" target="_blank" v-if="conference.conference_recording_transcript_url">
              <v-list-item-icon>
                <v-icon color="primary">mdi-script-text-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>BAO Conference Transcript</v-list-item-content>
            </v-list-item>
            <!-- Add more entries as needed for summary, next steps, timeline, etc. -->
            <v-list-item :href="conference.conference_recording_summary_url" target="_blank" v-if="conference.conference_recording_summary_url">
              <v-list-item-icon>
                <v-icon color="primary">mdi-file-powerpoint-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>BAO Conference Summary</v-list-item-content>
            </v-list-item>
            <v-list-item :href="conference.conference_recording_voice_url" target="_blank" v-if="conference.conference_recording_voice_url">
              <v-list-item-icon>
                <v-icon color="primary">mdi-file-video-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>BAO Conference Voice Recording</v-list-item-content>
            </v-list-item>
            <v-list-item :href="conference.conference_recording_timeline_url " target="_blank" v-if="conference.conference_recording_timeline_url">
              <v-list-item-icon>
                <v-icon color="primary">mdi-timeline-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>BAO Conference Timeline</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>

       <!-- Conversation Resource -->
       <v-col cols="12" v-if="conversation">
        <v-card class="pa-3 mb-3" outlined>
          <v-card-title>
            <v-icon large left color="primary">mdi-chat-outline</v-icon>
            IAO Conversation
          </v-card-title>
          <v-card-subtitle>{{ conversation.description }}</v-card-subtitle>
          <v-card-actions class="justify-end">
            <v-btn text :href="conversation.weblink" target="_blank">Open <v-icon right>mdi-open-in-new</v-icon></v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <!-- StatusPage Resource -->
      <v-col cols="12" v-if="statuspage_id">
        <v-card class="pa-3 mb-3" outlined>
          <v-card-title>
            <v-icon large left color="primary">mdi-list-status</v-icon>
            IAO StatusPage
          </v-card-title>
          <v-card-subtitle>
            Real-time IAO status and incident communication platform
          </v-card-subtitle>
          <v-card-actions class="justify-end">
            <v-btn text :href="unstatus_url" target="_blank">Open <v-icon right>mdi-open-in-new</v-icon></v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <!-- Ticket Resource -->
      <v-col cols="12" v-if="ticket">
        <v-card class="pa-3 mb-3" outlined>
          <v-card-title>
            <v-icon large left color="primary">mdi-ticket</v-icon>
            IAO Ticket
          </v-card-title>
          <v-card-subtitle>{{ ticket.description }}</v-card-subtitle>
          <v-card-actions class="justify-end">
            <v-btn text :href="ticket.weblink" target="_blank">Open <v-icon right>mdi-open-in-new</v-icon></v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
       <!-- Document Resource -->
       <v-col cols="12" v-for="document in documents" :key="document.resource_id">
        <v-card class="pa-3 mb-3" outlined>
          <v-card-title>
            <v-icon large left color="primary">{{ getIconForDocument(document.resource_type) }}</v-icon>
            {{ document.resource_type | deslug }}
          </v-card-title>
          <v-card-subtitle>{{ document.description }}</v-card-subtitle>
          <v-card-actions class="justify-end">
            <v-btn text :href="document.weblink" target="_blank">Open <v-icon right>mdi-open-in-new</v-icon></v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <!-- IAO Analysis & IAO AAR -->
      <v-col cols="12" v-if="storycurve_sketch_id">
        <v-card class="pa-3 mb-3" outlined>
          <v-card-title>
            <v-icon large left color="primary">mdi-strategy</v-icon>
            IAO Analysis & Insights
          </v-card-title>
          <v-card-subtitle>
            Collaborative forensic & unstructured data analysis and intelligence platform
          </v-card-subtitle>
          <v-card-actions class="justify-end">
            <v-btn text :href="`${storycurve_url}/sketch/${storycurve_sketch_id}/explore?timeline=${storycurve_sketch_id}`" target="_blank">Open <v-icon right>mdi-open-in-new</v-icon></v-btn>
          </v-card-actions>
        </v-card>
      </v-col>



      <!-- IAO AAR Conference Resources -->
      <v-col cols="12" v-for="postmortem in postmortems" :key="postmortem.id">
        <v-card class="pa-3 mb-3" outlined>
          <v-card-title>
            <v-icon large left color="primary">mdi-video-wireless-outline</v-icon>
            IAO AAR Conference
          </v-card-title>
          <v-card-subtitle v-if="postmortem.conference">{{ postmortem.conference.description }}</v-card-subtitle>
          <v-list>
            <v-list-item :href="postmortem.conference.weblink" target="_blank" v-if="postmortem.conference && postmortem.conference.weblink">
              <v-list-item-content>Main Conference Link</v-list-item-content>
              <v-list-item-icon>
                <v-icon color="error">mdi-open-in-new</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :href="postmortem.conference.conference_recording_transcript_url" target="_blank" v-if="postmortem.conference && postmortem.conference.conference_recording_transcript_url">
              <v-list-item-icon>
                <v-icon color="primary">mdi-script-text-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>BAO AAR Conference Transcript</v-list-item-content>
            </v-list-item>
            <v-list-item :href="postmortem.conference.conference_recording_voice_url" target="_blank" v-if="postmortem.conference && postmortem.conference.conference_recording_voice_url">
              <v-list-item-icon>
                <v-icon color="primary">mdi-file-video-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>BAO AAR Conference Voice Recording</v-list-item-content>
            </v-list-item>
            <!-- Add more entries as needed for summary, next steps, timeline, etc. -->
            <v-list-item :href="postmortem.conference.conference_recording_timeline_url " target="_blank" v-if="postmortem.conference && postmortem.conference.conference_recording_timeline_url">
              <v-list-item-icon>
                <v-icon color="primary">mdi-timeline-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>BAO AAR Conference Timeline</v-list-item-content>
            </v-list-item>
            <v-list-item :href="postmortem.conference.conference_recording_summary_url" target="_blank" v-if="postmortem.conference && postmortem.conference.conference_recording_summary_url">
              <v-list-item-icon>
                <v-icon color="primary">mdi-file-powerpoint-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>BAO AAR Conference Summary</v-list-item-content>
            </v-list-item>
            <v-list-item :href="postmortem.conference.conference_recording_summary_next_steps_url" target="_blank" v-if="postmortem.conference && postmortem.conference.conference_recording_summary_next_steps_url">
              <v-list-item-icon>
                <v-icon color="primary">mdi-step-forward</v-icon>
              </v-list-item-icon>
              <v-list-item-content>BAO AAR Conference Summary & Next Steps</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>

      <!-- Storage Resource -->
      <v-col cols="12" v-if="storage">
        <v-card class="pa-3 mb-3" outlined>
          <v-card-title>
            <v-icon large left color="primary">mdi-database-import-outline</v-icon>
            IAO Storage
          </v-card-title>
          <v-card-subtitle>{{ storage.description }}</v-card-subtitle>
          <v-card-actions class="justify-end">
            <v-btn text :href="storage.weblink" target="_blank">Open <v-icon right>mdi-open-in-new</v-icon></v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <!-- Queries Resource -->
      <v-col cols="12" md="6" sm="6" v-for="query in queries" :key="query.id">
        <v-card class="pa-3 mb-3" outlined>
          <v-card-title>
            <v-icon large left color="primary">mdi-database-import-outline</v-icon>
            {{ query.name }}
          </v-card-title>
          <v-card-subtitle>{{ query.description }}</v-card-subtitle>
          <v-card-actions>
            <v-btn
              icon
              @click="showRun({ type: 'incident', data: item })"
              :disabled="status == 'Closed'">
              <v-icon right color="success">mdi-play-circle-outline</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn icon @click="openQueryDialog(query)">
              <v-icon color="primary">mdi-file-find-outline</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <!-- Query Dialog -->
      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title class="text-h5">Query Text</v-card-title>
          <v-card-text>
            <p>{{ currentQuery.text }}</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-icon @click="copyQueryToClipboard(currentQuery.text)">mdi-content-copy</v-icon>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Weblinks Resource -->
      <v-col cols="12" md="6" sm="6" v-for="(weblink, index) in weblinks" :key="index">
        <v-card class="pa-3 mb-3" outlined>
          <v-card-title>
            <v-icon large left color="primary">mdi-link-variant</v-icon>
            {{ weblink.title }}
          </v-card-title>
          <v-card-subtitle>{{ weblink.description }}</v-card-subtitle>
          <v-card-actions class="justify-end">
            <v-btn text :href="weblink.url" target="_blank">Open <v-icon right>mdi-open-in-new</v-icon></v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <!-- Commands Resource -->
      <v-col cols="12" md="6" v-for="command in commands" :key="command.id">
        <v-card class="pa-3 mb-3" outlined>
          <v-card-title>
            <v-icon large left color="primary">mdi-console</v-icon>
            {{ command.name }}
          </v-card-title>
          <v-card-subtitle>{{ command.description }}</v-card-subtitle>
          <v-card-actions>
            <v-btn
              icon
              @click="showRun({ type: 'incident', data: item })"
              :disabled="status == 'Closed'">
              <v-icon right color="success">mdi-play-circle-outline</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn icon @click="openCommandDialog(command)">
              <v-icon color="primary">mdi-file-find-outline</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <!-- Command Dialog -->
      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title class="text-h5">Command Text</v-card-title>
          <v-card-text>
            <p>{{ currentCommand.command_text }}</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-icon @click="copyQueryToClipboard(currentCommand.command_text)">mdi-content-copy</v-icon>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Add New Resource -->
      <v-col cols="12" md="6">
        <v-card class="pa-3 mb-3" outlined>
          <v-card-title>
            <v-icon large left color="primary">mdi-file-plus</v-icon>
            Add New Resource
          </v-card-title>
          <v-card-text>
            <v-form @submit.prevent="addNewResource">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="newResource.title"
                    label="Title"
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="newResource.weblink"
                    label="Weblink"
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="newResource.description"
                    label="Description"
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="newResource.resource_type"
                    :items="resourceTypes"
                    label="Resource Type"
                    outlined
                    required
                    @change="onResourceTypeChange"
                  ></v-select>
                </v-col>
                <v-col cols="12" v-if="newResource.resource_type === 'document'">
                  <v-select
                    v-model="newResource.subtype"
                    :items="documentSubtypes"
                    label="Document Subtype"
                    outlined
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    type="submit"
                    class="add-button-tick"
                    :loading="isAddingResource"
                    :disabled="isAddingResource"
                    color="success"
                  >
                    Add
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"

export default {
  name: "BAOResourcesTab",

  data() {
    return {
      newResource: {
          weblink: '',
          description: '',
          resource_type: '',
          subtype: '',
          title: '',
      },
      resourceTypes: [
          'ticket',
          'storage',
          'documents',
          'conference',
          'conversation',
          'storycurve_sketch',
          'other',
      ],
      documentSubtypes: ['pdf', 'word', 'spreadsheet', 'presentation', 'document', 'image', 'audio'],
      isAddingResource: false,
      dialog: false,
      currentQuery: {},
      currentCommand: {},
    };
  },


  computed: {
    ...mapFields("incident", [
      "selected.ticket",
      "selected.storage",
      "selected.documents",
      "selected.conference",
      "selected.conversation",
      "selected.storycurve_sketch_id",
      "selected.storycurve_url",
      "selected.statuspage_id",
      "selected.unstatus_url",
      "selected.queries",
      "selected.postmortems",
      "selected.id",
      "selected.weblinks",
      "selected.commands",
      "selected.status",
    ]),
    isDarkTheme() {
      return localStorage.getItem("dark_theme") === 'true';
    },
    iconColor() {
      return this.isDarkTheme ? 'white--text' : 'black--text';
    },
    bgColor() {
      return this.isDarkTheme ? 'blue-grey darken-4' : 'blue-grey lighten-5'
    }
  },

  methods: {
    ...mapActions("incident", ["addResource", "get", "copyToClipboardSuccess"]),
    ...mapActions("workflow", ["showRun"]),
    onResourceTypeChange() {
        if (this.newResource.resource_type !== 'document') {
            this.newResource.subtype = '';
        }
    },
    openQueryDialog(query) {
      this.currentQuery = query;
      this.dialog = true;
    },
    openCommandDialog(command) {
      this.currentCommand = command;
      this.dialog = true;
    },

    copyQueryToClipboard(queryText) {
      navigator.clipboard.writeText(queryText).then(() => {
        this.copyToClipboardSuccess();
      });
    },

    addNewResource() {
      this.isAddingResource = true;
      const resourceData = {
        weblink: this.newResource.weblink,
        description: this.newResource.description,
        resource_type: this.newResource.resource_type,
        subtype: this.newResource.subtype,
        title: this.newResource.title,
      };

      let retryCount = 0;
      this.addResource(resourceData).then (() => {
        const intervalId = setInterval(() => {
          this.get(this.id).then(() => {
            this.resetNewResourceForm();
            // Check if the resource is added. This is not working as expected. TODO.
            const isResourceAdded = this.weblinks.find((weblink) => weblink.url === this.newResource.weblink);
            if (isResourceAdded) {
              clearInterval(intervalId);
            } else {
              retryCount += 1;
              if (retryCount > 2) {
                clearInterval(intervalId);
              }
            }
          });
        }, 2000);
        })
        .catch((error) => {
            console.log('Error adding resource: ', error);
        })
        .finally(() => {
          this.isAddingResource = false;
        });
    },
    resetNewResourceForm() {
      this.newResource = {
        weblink: '',
        description: '',
        resource_type: '',
        subtype: '',
        title: '',
      };
    },
    getIconForDocument(resourceType) {
      if (/review/i.test(resourceType)) {
      // This regex matches any resource type containing 'review'
        return 'mdi-strategy';  // Change this to your preferred review document icon
      } else {
        // Default icon for all other document types
        return 'mdi-file-document-outline';
      }
    },
  },
}
</script>

<style scoped>
.add-resource-title {
  background-color: #263238;
}
.add-button-tick {
  background-color: #ECEFF1;
}
.incident-resources-list {
  padding: 0;
}

.incident-resources-title {
  font-weight: bold;
}

.incident-resources-subtitle {
  color: #616161;
}
</style>
