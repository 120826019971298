<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container fluid>
  <v-card elevation="1" outlined class="position-relative">
    <v-row>
      <v-col cols="8" style="align-self: center" pl="4">
        <v-card-title class="pb-0 mb-0">
          <v-icon small class="mr-1">mdi-account-multiple-check-outline</v-icon>
          {{ participant.individual.email }}
          <incident-participant :participant="participant" />
        </v-card-title>
      </v-col>
      <v-col class="text-right" cols="4" v-if="count > 1" style="align-self: center">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-badge color="grey" :content="badgeCount" overlap offset-x="45" offset-y="37">
                <v-card-subtitle></v-card-subtitle>
              </v-badge>
            </span>
          </template>
          <span>This participant has been in {{ count }} roles in this incident</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-card-text style="font-size: 18px">
      <v-icon small>mdi-account-details-outline</v-icon>
      Participant Roles
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Role</th>
              <th class="text-left">Activity</th>
              <th class="text-left">Status</th>
              <th class="text-left">Duration</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="role in participant.participant_roles" :key="role.id">
              <td>
                <v-chip>
                  <v-icon small :color="roleIconColor(role.role)" class="mr-1">{{ roleIcon(role.role) }}</v-icon>
                  {{ role.role }}
                </v-chip>
              </td>
              <td>{{ role.activity }}</td>
              <td :class="{ 'active': isActiveRole(role), 'renounced': !isActiveRole(role) }">
                <v-chip small dark :color="isActiveRole(role) ? 'success' : 'warning'">
                  <v-icon small left>
                    {{ isActiveRole(role) ? 'mdi-check' : 'mdi-close' }}
                  </v-icon>
                  {{ isActiveRole(role) ? 'Active' : 'Renounced' }}
                </v-chip>
              </td>
              <td>{{ roleDuration(role) }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-divider></v-divider>
    <v-hover>
      <template v-slot:default="{ hover }">
        <v-card
          outlined
          rounded="xl"
          class="d-flex align-center mx-4 mt-3 mb-3"
          :elevation="hover ? 2 : 0"
          @click="openIncidentTab"
        >
          <v-card-text class="d-flex align-center">
            <v-progress-linear
              v-if="isLoading"
              height="3"
              color="info"
              indeterminate
            ></v-progress-linear>
            <span v-else>
              <span v-if="incidentCount > 1">
                <v-icon large left color="secondary">mdi-chart-bell-curve</v-icon>
                  This participant has been seen in
                  <span v-bind:style="{ fontSize: incidentCount > 5 ? '20px' : '16px', fontWeight: incidentCount > 5 ? 'bold' : 'normal' }">{{ incidentCount }}</span>
                <v-icon small color="error">mdi-alert-outline</v-icon>
                other IAOs
              </span>
              <span v-else>
                <v-icon small color="error">mdi-progress-check</v-icon>
                First time this participant has been seen in an incident
              </span>
            </span>
          </v-card-text>
        </v-card>
      </template>
    </v-hover>
    <v-dialog v-model="incidentDialog" max-width="1080">
      <incident-tab :inputIncidents="incidents" />
    </v-dialog>
  </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex"
import { mapFields } from "vuex-map-fields"
import moment from "moment"

import IncidentTab from "@/incident/IncidentTab.vue"
import IncidentParticipantApi from "@/incident/participant/api"
import IncidentParticipant from "@/incident/Participant.vue"

export default {
  name: "IncidentParticipantCard",
  props: {
    participant: {
      type: Object,
      required: true,
    },
    count: {
      type: Number,
      default: 1,
    },
    selectedDateTime: {
      type: Number,
      required: true,
    },
  },
  components: {
    "incident-tab": IncidentTab,
    "incident-participant": IncidentParticipant,
  },
  computed: {
    ...mapFields("participant", ["dialogs.showParticipantView"]),
    ...mapFields("route", ["query"]),
    badgeCount() {
      return this.count >= 100 ? "x99+" : `x${this.count}`
    },
  },
  data() {
    return {
      incidentCount: null,
      isLoading: true,
      incidentDialog: false,
      incidents: [],
    }
  },
  async mounted() {
    await this.refreshData()
  },
  watch: {
    selectedDateTime() {
      this.refreshData()
    },
  },
  methods: {
    async refreshData() {
      try {
        this.isLoading = true

        const project_id = this.participant.individual.project.id

        const incidentPromise = IncidentParticipantApi.getIncidents(this.participant.individual.email, project_id, this.selectedDateTime).then(
          (response) => response.data
        )

        const [incidentsResponse] = await Promise.all([incidentPromise])

        this.incidents = incidentsResponse.incidents
        this.incidentCount = this.incidents.length

        this.isLoading = false
      } catch (error) {
        console.error("Error in refreshData:", error)
      }
    },
    async openIncidentTab() {
      this.incidentDialog = true
      this.$nextTick(() => {
        this.$refs.incidentTab = this.incidents
      })
    },
    getStartDate() {
      return new Date(Date.now() - this.selectedDateTime * 86400000).toISOString()
    },
    isActiveRole(role) {
      return role.renounced_at === null;
    },
    roleDuration(role) {
      const assumedAt = moment(role.assumed_at);
      const renouncedAt = role.renounced_at ? moment(role.renounced_at) : moment();
      const duration = moment.duration(renouncedAt.diff(assumedAt));

      const days = duration.days();
      const hours = duration.hours();
      const minutes = duration.minutes();

      let durationString = "";
      if (days > 0) durationString += days + " days ";
      if (hours > 0) durationString += hours + " hours ";
      if (minutes > 0) durationString += minutes + " minutes";

      return durationString || "Less than a minute";
    },
    isLastParticipant(participant) {
      return this.participants.indexOf(participant) === this.participants.length - 1;
    },
    roleIcon(role) {
      // Map role to corresponding icon
      switch (role) {
        case "Activity Lead":
          return "mdi-account-hard-hat";
        case "Scribe":
          return "mdi-account-hard-hat";
        case "Liaison":
          return "mdi-account-tie";
        case "Participant":
          return "mdi-account";
        case "Observer":
          return "mdi-eye";
        case "Reporter":
          return "mdi-account-cog";
        case "Assignee":
          return "mdi-account-cog";
        default:
          return "mdi-account";
      }
    },
    roleIconColor(role) {
      // Apply additional classes based on role
      switch (role) {
        case "Activity Lead":
          return "error";
        case "Scribe":
          return "blue";
        case "Liaison":
          return "success";
        case "Participant":
          return "warning";
        case "Observer":
          return "purple";
        case "Reporter":
          return "blue";
        case "Assignee":
          return "primary";
        default:
          return "black";
      }
    },
    roleIconClass(role) {
      // Apply additional classes based on role
      switch (role) {
        case "Activity Lead":
          return "admin-icon";
        case "Scribe":
          return "engineer-icon";
        case "Liaison":
          return "manager-icon";
        case "Participant":
          return "participant-icon";
        case "Observer":
          return "observer-icon";
        case "Reporter":
          return "reporter-icon";
        default:
          return "";
      }
    },
  },

}
</script>

<style scoped>
.participants-list .v-list-item {
  padding: 14px;
}
.role-active {
  color: green;
}

.v-list-item-avatar {
  margin-right: 5px;
}

.role-renounced {
  color: rgb(0, 98, 255);
}
.participant-item:active {
  background-color: #e0e0e0;
}
.participant-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.empty-participants-message {
  text-align: center;
  padding: 2rem 0;
}

.participants-list > v-list-item-group > div:last-child > v-divider {
  display: none;  /* Hide the last divider */
}
.incident-participants-tab {
  max-width: 1200px;
  margin: 0;
}

.participants-list {
  padding-left: 0;
}

.participant-info {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.participant-name {
  font-size: 18px;
  font-weight: bold;
  margin-right: 8px;
}

.participant-roles {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.participant-role {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.role-name {
  margin-left: 4px;
}

.participant-details {
  font-size: 14px;
  color: #666666;
}

.empty-participants-message {
  margin-top: 16px;
  text-align: center;
  color: #666666;
}
.admin-icon {
  color: #D32F2F;
}
.role-activity {
  color: #666;
  margin-right: 8px;
}

.engineer-icon {
  color: #1976D2;
}

.manager-icon {
  color: #388E3C;
}

.participant-icon {
  color: #F57C00;
}

.observer-icon {
  color: #8E24AA;
}

.reporter-icon {
  color: #0288D1;
}

.role-name {
  margin-right: 8px;
}

.role-status {
  margin-right: 8px;
  font-weight: bold;
}

.active {
  color: green;
}

.renounced {
  color: red;
}

.role-duration {
  color: #666;
}
</style>
