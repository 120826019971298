<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container>
    <v-row no-gutter align="center" style="margin-bottom: 20px;">
      <v-chip color="teal" text-color="white">
        <v-icon left>mdi-form-textbox</v-icon>
        IAO Custom Fields
      </v-chip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon color="info" v-bind="attrs" v-on="on" @click="addField()" class="ml-2">
            <v-icon large>mdi-playlist-plus</v-icon>
          </v-btn>
        </template>
        <span>This custom field gives the flexibility to add custom fields to the incident, ensuring that all the necessary information is captured.</span>
      </v-tooltip>
    </v-row>

    <!-- Draggable Component -->
    <draggable v-model="customFields" @end="onDragEnd">
      <v-row v-for="(field, idx) in customFields" :key="idx" align="center" dense>
        <!-- Drag Handle -->
        <v-col cols="1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>mdi-drag-vertical</v-icon>
              </v-btn>
            </template>
            <span>Drag to reorder</span>
          </v-tooltip>
        </v-col>

        <v-col cols="12" sm="5">
          <v-text-field
            label="Field Name"
            :value="field.name"
            @input="updateFieldName(idx, $event)"
          />
        </v-col>

        <v-col cols="12" sm="5">
          <v-text-field
            label="Field Value"
            :value="field.value"
            @input="updateFieldValue(idx, $event)"
          />
        </v-col>

        <!-- Remove Field -->
        <v-col cols="1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn small icon @click="removeField(idx)" v-on="on">
                <v-icon>remove</v-icon>
              </v-btn>
            </template>
            <span>Remove Field</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </draggable>
  </v-container>
</template>

  <script>
  import { cloneDeep } from "lodash"

  import draggable from "vuedraggable"

  export default {
    name: "IncidentCustomFieldsInput",

    components: {
      draggable,
    },

    props: {
      value: {
        type: Array,
        default: () => [],
      },
    },

    data() {
      return {
        customFields: [],
      };
    },

    watch: {
    value: {
      immediate: true,
      handler(newVal) {
          this.customFields = cloneDeep(newVal);
        },
      },
    },

    methods: {
      addField() {
        if (!this.customFields) {
          this.customFields = []
        }
        this.customFields.push({ name: null, value: null })
        this.$emit("input", this.customFields)
      },
      removeField(idx) {
        this.customFields.splice(idx, 1)
        this.$emit("input", this.customFields)
      },
      updateFieldName(idx, event) {
        this.customFields[idx].name = event
        this.$emit("input", this.customFields)
      },
      updateFieldValue(idx, event) {
        this.customFields[idx].value = event
        this.$emit("input", this.customFields)
      },
      onDragEnd() {
        this.customFields.forEach((item, index) => {
          item.order = index + 1
        } )
        this.$emit("input", this.customFields)
      },
    },
  }
  </script>
