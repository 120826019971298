<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container fluid>
    <v-row class="ml-6 mr-6 justify-center">
      <!--  IAO Activity Graph -->
      <v-col class="pa-1" cols="6">
        <incident-event-graph
          :incident="selected"
          :events="selected.events"
        />
      </v-col>

      <!-- Chip Group -->
      <v-col class="pa-1 mt-15" cols="6">
        <v-chip-group v-model="currentFilter" active-class="primary--text" column>
          <v-chip @click="setFilter('All')">
            <v-icon left small color="primary">mdi-filter</v-icon>
            All
          </v-chip>
          <v-chip v-for="(typeValue, type) in eventTypeToFilter" :key="type" @click="setFilter(type)">
            <v-icon left small color="primary">{{ eventTypeToIcon[type] }}</v-icon>
            {{ type }}
          </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>

    <v-row justify="space-between" class="my-0 ml-10 mr-10">
      <v-col cols="20" sm="8" md="5" class="d-flex align-end">
        <v-text-field v-model="search" label="Search..." append-icon="mdi-magnify"></v-text-field>
      </v-col>
      <v-col cols="12" sm="8" md="1" class="d-flex align-end">
        <v-switch v-model="showDetails" label="Show Details" color="primary" class="mr-3" />
      </v-col>
      <v-col cols="12" sm="8" md="1" class="d-flex align-end">
        <v-btn small color="gray" @click="refetchData" :loading="loading">
          <v-icon small>mdi-refresh</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="8" md="1"  class="d-flex justify-end align-end">
        <v-btn small color="primary" @click="exportToCSV" :loading="exportLoading" class="mr-3">
          <v-icon small left>mdi-export</v-icon>
          Export
        </v-btn>
        <timeline-filter-dialog ref="filter_dialog" />
        <edit-event-dialog />
        <delete-event-dialog />
      </v-col>
    </v-row>
    <v-timeline v-if="events && events.length" dense clipped class="ml-10 mr-10">
      <v-col class="text-right caption time-zone-notice">(times in UTC)</v-col>
      <v-row>
        <div
          class="add-event"
          style="--margtop: 0px; --margbot: 10px; --margrule: 20px; margin-left: 85px; margin-bottom: 15px;"
        >
          <div class="horiz-rule" />
          <div class="add-button">
            <v-btn
              compact
              small
              plain
              class="up-button"
              @click="showNewPreEventDialog(new Date().toISOString())"
            >
              <v-icon color="#AED581" small class="mr-1">mdi-plus-circle-outline</v-icon>Add event
            </v-btn>
          </div>
        </div>
      </v-row>
      <v-timeline-item
        v-for="event in sortedAndFilteredEvents"
        v-show="showItem(event)"
        :icon="iconItem(event)"
        :key="event.id"
        :color="getEventColor(event)"
        class="mb-4"
        :class="classType(event)"
      >
        <v-row justify="space-between">
          <v-col cols="12" md="6">
            {{ event.description }}
            <transition-group name="slide" v-if="showDetails">
              <template v-for="(value, key) in event.details">
                <v-card class="event-detail" flat :key="key">
                  <v-card-text class="event-detail-title">{{ key | snakeToCamel }}</v-card-text>
                  <v-card-text class="event-detail-value">{{ value }}</v-card-text>
                </v-card>
              </template>
            </transition-group>
            <div class="event-source">{{ event.source }}</div>
          </v-col>
          <v-col cols="1">
            <div v-if="isEditable(event)" class="custom-event-edit">
              <v-btn plain small @click="showEditEventDialog(event)">
                <v-icon>mdi-book-edit-outline</v-icon>
              </v-btn>
              <br />
              <v-btn plain small @click="togglePin(event)">
                <v-hover v-slot="{ hover }">
                  <v-icon v-if="!isPinned(event)">mdi-pin-outline</v-icon>
                  <v-icon v-else-if="hover && isPinned(event)">mdi-pin-off</v-icon>
                  <v-icon color="tips" v-else-if="!hover && isPinned(event)">mdi-pin</v-icon>
                </v-hover>
              </v-btn>
              <br />
              <v-btn plain small @click="showDeleteEventDialog(event)">
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </div>
            <div v-if="isPinned(event) && !isEditable(event)" class="pinned-event">
              <v-btn plain small @click="togglePin(event)">
                <v-hover v-slot="{ hover }">
                  <v-icon v-if="hover">mdi-pin-off</v-icon>
                  <v-icon color="blue" v-else>mdi-pin</v-icon>
                </v-hover>
              </v-btn>
            </div>
            <div v-else-if="!isPinned(event) && !isEditable(event)" class="pinned-event">
              <v-btn plain small @click="togglePin(event)">
                <v-icon>mdi-pin-outline</v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col class="text-right" cols="4">
            <v-col>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on" class="wavy-underline">{{
                    event.started_at | formatRelativeDate
                  }}</span>
                </template>
                <span class="pre-formatted">{{ event.started_at | formatToTimeZones }}</span>
              </v-tooltip>
            </v-col>
          </v-col>
        </v-row>
        <v-row>
          <div class="add-event" style="--margtop: -40px; --margbot: 0px; --margrule: 40px">
            <div class="horiz-rule" />
            <div class="add-button">
              <v-btn
                compact
                small
                plain
                class="up-button"
                @click="showNewEventDialog(event.started_at)"
              >
                <v-icon color="#AED581" small class="mr-1">mdi-plus-circle-outline</v-icon>Add event
              </v-btn>
            </div>
          </div>
        </v-row>
      </v-timeline-item>
    </v-timeline>
    <div v-else>
      <p class="text-center">No timeline events available.</p>
    </div>
    <div class="text-caption ml-10" v-if="countHidden() != 0">
      {{ "" + countHidden() }} event(s) are hidden due to current filter
    </div>
  </v-container>
</template>

<script>
import { sum } from "lodash"
import { mapFields } from "vuex-map-fields"
import { mapState } from "vuex"
import { mapActions } from "vuex"
import Util from "@/util"
import { v4 as uuidv4 } from "uuid"
import TimelineFilterDialog from "@/incident/TimelineFilterDialog.vue"
import EditEventDialog from "@/incident/EditEventDialog.vue"
import DeleteEventDialog from "@/incident/DeleteEventDialog.vue"
import IncidentTimelineChart from "@/incident/IncidentTimelineChart.vue"
import IncidentEventGraph from "@/event/IncidentEventGraph.vue"

const eventTypeToIcon = {
  Other: "mdi-monitor-star",
  "Field updated": "mdi-subtitles-outline",
  "Assessment updated": "mdi-priority-high",
  "Participant updated": "mdi-account-outline",
  "Custom event": "mdi-text-account",
  "Imported message": "mdi-page-next-outline",
  "Notification sent": "mdi-email-outline",
  "AI generated": "mdi-creation",
  "Resource updated": "mdi-folder-multiple-outline",
  "Conversation message": "mdi-message-text-outline",
  "Step updated": "mdi-step-forward",
  "Task updated": "mdi-calendar-check",
  "Context updated": "mdi-transfer",
}

const eventTypeToColor = {
  Other: "blue",
  "Field updated": "light-blue",
  "Assessment updated": "warning",
  "Participant updated": "success",
  "Custom event": "deep-purple",
  "Imported message": "indigo",
  "Notification sent": "teal",
  "AI generated": "lime",
  "Resource updated": "warning",
  "Conversation message": "purple",
  "Step updated": "cyan",
  "Task updated": "pink",
  "Context updated": "brown",
}

const eventTypeToFilter = {
  Other: "other_events",
  "Field updated": "field_updates",
  "Assessment updated": "assessment_updates",
  "Participant updated": "participant_updates",
  "Custom event": "user_curated_events",
  "Notification sent": "notification_events",
  "AI generated": "ai_generated_events",
  "Resource updated": "resource_updates",
  "Conversation message": "conversation_events",
  "Step updated": "step_updates",
  "Task updated": "task_updates",
  "Context updated": "context_updates",
}

export default {
  name: "IncidentTimelineTab",

  components: {
    TimelineFilterDialog,
    EditEventDialog,
    DeleteEventDialog,
    IncidentTimelineChart,
    IncidentEventGraph,
  },

  data() {
    return {
      showDetails: false,
      exportLoading: false,
      loading: false,
      newComment: "",
      search: "",
      rules: {
        required: (value) => !!value || "This field is required",
      },
      transformedChartData: {},
      eventTypeToFilter: eventTypeToFilter,
      eventTypeToIcon: eventTypeToIcon,
    }
  },

  mounted() {
    this.transformData()
  },
  computed: {
    ...mapFields("incident", ["selected.events", "selected.name", "selected.id", "timeline_filters", "currentFilter", "selected"]),
    ...mapState("auth", ["currentUser"]),

    isCommentEmpty() {
      return this.newComment.trim() === ""
    },
    sortedAndFilteredEvents() {
      let events = this.events
        .slice()
        .sort((a, b) => new Date(b.started_at) - new Date(a.started_at))

      // First, apply the chip filter if a specific filter is selected
      if (this.currentFilter && this.currentFilter !== 'All') {
        console.log(this.currentFilter)
        events = events.filter((event) => eventTypeToFilter[event.type] === this.currentFilter)
      }

      // Then, further filter the already filtered list by the search text
      if (this.search) {
        events = events.filter(
          (event) =>
            event.description.toLowerCase().includes(this.search.toLowerCase()) ||
            event.source.toLowerCase().includes(this.search.toLowerCase())
        )
      }
      return events
    },
  },
  methods: {
    ...mapActions("incident", [
      "showNewEventDialog",
      "showEditEventDialog",
      "showDeleteEventDialog",
      "showNewPreEventDialog",
      "togglePin",
      "addComment",
      "getDetails",

    ]),
    setFilter(filter) {
      this.currentFilter = filter
    },
    refetchData() {
      this.getDetails({ id: this.id })
    },
    transformData() {
      const chartData = {
        labels: this.events.map(event => event.started_at),
        datasets: [
          {
            label: 'Incidents',
            backgroundColor: '#f87979',
            data: this.events.map((event, index) => ({
              x: event.started_at,
              y: event.id,
            }))
          }
        ]
      };

      this.transformedChartData = chartData;
    },
    getEventColor(event) {
      return eventTypeToColor[event.type]
    },

    exportToCSV() {
      this.exportLoading = true
      const selected_items = []
      let items = this.sortedAndFilteredEvents
      items.forEach((item) => {
        if (this.showItem(item)) {
          selected_items.push(item)
        }
      })
      Util.exportCSV(
        selected_items.map((item) => ({
          "Time (in UTC)": item.started_at,
          Description: item.description,
          Owner: this.extractOwner(item),
        })),
        this.name + "-timeline-export.csv"
      )
      this.exportLoading = false
    },
    addComment() {
      if (this.$refs.form.validate()) {
        const commentEvent = {
          uuid: uuidv4(),
          description: this.newComment,
          details: {},
          source: this.currentUser.email,
          started_at: new Date().toISOString(),
          ended_at: new Date().toISOString(),
        }
        this.events.push(commentEvent)
        this.$store.dispatch("incident/addComment", { incidentId: this.id, comment: commentEvent })
        this.newComment = ""
        this.$refs.form.resetValidation()
      }
    },
    showItem(event) {
      if (event.pinned) return true
      return !this.timeline_filters[eventTypeToFilter[event.type]]
    },
    iconItem(event) {
      if (event.description == "Incident created") return "mdi-flare"
      return eventTypeToIcon[event.type]
    },
    extractOwner(event) {
      if (event.owner != null && event.owner != "") return event.owner
      return "Dispatch"
    },
    countHidden() {
      if (!this.events) return 0
      return sum(
        this.events.map((e) => {
          return this.timeline_filters[eventTypeToFilter[e.type]] || false
        })
      )
    },
    isEditable(event) {
      return event.type == "Custom event" || event.type == "Imported message"
    },
    classType(event) {
      if (event.type == "Custom event" || event.type == "Imported message") {
        return "custom-event"
      }
      return "pinned-event"
    },
    isPinned(event) {
      return event.pinned
    },
  },
}
</script>

<style scoped>
.my-2 {
  margin-top: 0px;
  margin-bottom: 0px;
}


.event-description {
  font-weight: bold;
  margin-bottom: 2px;
}

.event-detail {
  margin-top: 2px;
  padding: 2px;
  border-radius: 4px;
}

.event-detail-title {
  font-weight: bold;
  color: #424242;
}

.event-detail-value {
  color: #616161;
}

.event-source {
  margin-top: 2px;
  font-style: italic;
  color: #757575;
}

.event-date {
  font-size: 12px;
  color: #757575;
}


.v-btn {
  text-transform: none;
  border-radius: 8px;
}

.align-end {
  align-items: flex-end;
}
</style>
