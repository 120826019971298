<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <div>
    <v-card class="mx-auto" max-width="1000">
      <v-card-title>
        Interactive Activity Object (IAO) Outcome Timeline
      </v-card-title>
      <v-card-text>
        <apexchart :key="chartKey" ref="apexChart" type="line" height="350" :options="chartOptions" :series="timelineSeries"></apexchart>
      </v-card-text>
    </v-card>

    <v-row class="mx-auto ml-6 mr-6" justify="space-around">
      <v-col cols="12" md="4" v-for="(step, index) in steps" :key="index" class="mb-5">
        <v-card outlined tile>
          <v-card-title class="headline">
            <v-icon left color="primary">mdi-debug-step-over</v-icon>
            {{ step.name }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-subheader>
                  <v-icon small left>mdi-calendar</v-icon>
                  Started At</v-subheader>
                <div>{{ step.time_started | formatRelativeDate }}</div>
              </v-col>
              <v-col cols="6">
                <v-subheader>
                  <v-icon small left>mdi-calendar</v-icon>
                  Completed At</v-subheader>
                <div class="ml-4 primary--text">
                  <v-icon small left>mdi-calendar</v-icon>
                  {{ step.time_completed | formatRelativeDate}}
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              color="error--text"
              @click="showStepSummary(step)"
              :loading="stepAiSummaryLoading">
              <v-icon left>mdi-creation</v-icon>
              How?
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <step-ai-summary-dialog
      v-if="activeStep"
      :stepAiSummary="stepAiSummary"
      :stepName="activeStep.name"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields';
import stepAiSummaryDialog from '@/incident/steps/StepAiSummaryDialog.vue'
import { get } from 'lodash';

export default {
  components: {
    apexchart: VueApexCharts,
    stepAiSummaryDialog,
  },
  data() {
    return {
      showSummaryDialog: false,
      activeStep: null, // The currently active step
      chartKey: 0,
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: true
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: 'Interactive Activity Object (IAO) Outcome Timeline',
          align: 'left'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          },
        },
        xaxis: {
          categories: this.stepNames, // This will be populated with the step names
        },
        yaxis: {
          title: {
            text: 'Interactive Activity Object (IAO) Progress'
          },
          min: 0,
          max: 100, // Assuming this is a percentage, adjust based on your needs
        },
        annotations: {
          xaxis: [
            {
              x: this.rootCauseStepName, // This will be populated with the name of the root cause step
              borderColor: '#FF4560',
              label: {
                borderColor: '#FF4560',
                style: {
                  color: '#fff',
                  background: '#FF4560',
                },
                text: this.rootCauseStepName, // This will be populated with the name of the root cause step
              }
            }
          ]
        }
      }
    }
  },
  methods: {
    ...mapActions("incident", ["getStepAiSummary"]),
    showStepSummary(step) {
      this.activeStep = step; // Set the active step
      this.getStepAiSummary({ stepId: step.id, incidentId: this.id });
    },
  },
  computed: {
    ...mapFields('incident', [
        "selected.steps",
        "selected.created_at",
        "selected.stable_at",
        "stepAiSummaryLoading",
        "dialogs.showStepAiSummaryDialog",
        "selected.id",
        "stepAiSummary"
      ]
    ),
    rootCauseIndex() {
    // Find the index of the first step containing "cause" in its name
      const index = this.steps.findIndex(step => step.name.toLowerCase().includes('cause'));
      return index;
    },
    rootCauseStepName() {
      return this.steps[this.rootCauseIndex].name;
    },
    stepNames() {
      const names = this.steps.map(step => step.name)
      return names
    },
    timelineSeries() {
      if (!this.steps.length) return [{ name: 'Timeline', data: [] }];

      // Assuming 'this.startTime' and 'this.endTime' are available
      const startTime = new Date(this.created_at).getTime();
      const endTime = new Date(this.stable_at).getTime() || new Date().getTime();
      const totalDuration = endTime - startTime;

      const seriesData = this.steps.map(step => {
        const stepCompletionTime = new Date(step.time_completed).getTime() || endTime;
        const timeSinceStart = stepCompletionTime - startTime;
        const completionPercentage = Math.round((timeSinceStart / totalDuration) * 100);
        return Math.max(Math.min(completionPercentage, 100), 0); // Ensure between 0 and 100, regardless of actual completion sequence
      });

      return [{ name: 'Timeline', data: seriesData }];
    }
  },

  watch: {
    stepNames: {
      immediate: true,
      handler(newNames) {
        this.$nextTick(() => {
          // Ensure DOM updates have completed
          this.chartOptions = {
            ...this.chartOptions,
            xaxis: {
              ...this.chartOptions.xaxis,
              categories: newNames
            }
          };
        });
      }
    },
    timelineSeries: {
      immediate: true,
      handler(newSeries) {
        this.$nextTick(() => {
          // Ensure DOM updates have completed
        });
      }
    },
    rootCauseIndex: {
    immediate: true,
    handler(newIndex) {
        this.$nextTick(() => {
            // Ensure DOM updates have completed
            this.chartOptions = {
                ...this.chartOptions,
                annotations: {
                    ...this.chartOptions.annotations,
                    xaxis: [
                        {
                            x: this.rootCauseStepName,
                            borderColor: '#FF4560',
                            label: {
                                borderColor: '#FF4560',
                                style: {
                                    color: '#fff',
                                    background: '#FF4560',
                                },
                                text: this.rootCauseStepName,
                            }
                        }
                    ]
                }
            };
            // Forcefully refresh or update the chart
            if (this.$refs.apexChart) {
                this.$refs.apexChart.updateOptions({ annotations: this.chartOptions.annotations }, false, true);
            }
            this.chartKey += 1;
        });
      }
    },
    rootCauseStepName: {
      immediate: true,
      handler(newName) {
        this.$nextTick(() => {
          this.chartOptions = {
                ...this.chartOptions,
                annotations: {
                    ...this.chartOptions.annotations,
                    xaxis: [
                        {
                            x: this.rootCauseStepName,
                            borderColor: '#FF4560',
                            label: {
                                borderColor: '#FF4560',
                                style: {
                                    color: '#fff',
                                    background: '#FF4560',
                                },
                                text: this.rootCauseStepName,
                            }
                        }
                    ]
                }
            };
        });
      }
    },
  },
}
</script>

<style scoped>
/* Add style if necessary */
</style>
