<template>
    <apexchart
      type="line"
      :options="chartOptions"
      :series="chartSeries"
      height="350"
    ></apexchart>
  </template>

  <script>
 import VueApexCharts from "vue-apexcharts"
  export default {
    name: "IncidentTimelineChart",
    components: {
      apexchart: VueApexCharts,
    },
    data() {
      return {
        chartOptions: {
          chart: {
            height: 350,
            type: 'line',
            zoom: {
              enabled: false
            },
            toolbar: {
              show: false
            }
          },
          stroke: {
            curve: 'straight'
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'],
              opacity: 0.5
            }
          },
          xaxis: {
            type: 'datetime'
          },
          tooltip: {
            x: {
              format: 'dd MMM yyyy HH:mm'
            }
          }
        },
        chartSeries: [
          {
            name: 'Events',
            data: {}
          }
        ]
      };
    },
    props: {
      events: {
        default() {
          return {};
        }
      }
    },
    created() {
      console.log(this.events);
      this.chartSeries[0].data = this.events.map(event => {
        return {
          x: new Date(event.started_at),
          y: event.id,
        };
      });
    }
  };
  </script>
