<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container grid-list-md fluid>
    <v-layout wrap>
      <v-flex xs12>
        <ValidationProvider name="Title" rules="required" immediate>
          <v-text-field
            v-model="title"
            slot-scope="{ errors, valid }"
            :error-messages="errors"
            :success="valid"
            label="Title"
            hint="Title of the IAO."
            clearable
            required
          />
        </ValidationProvider>
      </v-flex>
      <v-flex xs12>
        <ValidationProvider name="Description" rules="required" immediate>
          <v-textarea
            v-model="description"
            slot-scope="{ errors, valid }"
            :error-messages="errors"
            :success="valid"
            label="Description"
            hint="Description of the IAO."
            clearable
            required
          />
        </ValidationProvider>
      </v-flex>
      <v-flex xs12>
        <v-textarea
          v-model="resolution"
          label="Resolution"
          hint="Description of the actions taken to resolve the IAO."
          clearable
        />
      </v-flex>
      <v-flex xs6>
        <ValidationProvider name="Assignee" rules="required" immediate>
          <participant-select
            v-model="assignee"
            label="Assignee"
            hint="The organization member to which the IAO is assigned."
            clearable
            slot-scope="{ errors, valid }"
            :error-messages="errors"
            :success="valid"
            :project="project"
          />
      </ValidationProvider>
      </v-flex>
      <v-flex xs6>
        <ValidationProvider name="Reporter" rules="required" immediate>
          <participant-select
            v-model="reporter"
            slot-scope="{ errors, valid }"
            label="Reporter"
            :error-messages="errors"
            :success="valid"
            hint="The participant who reported the IAO."
            clearable
            required
            :project="project"
          />
        </ValidationProvider>
      </v-flex>
      <v-flex xs6>
        <ValidationProvider name="Activity Lead" rules="required" immediate>
          <participant-select
            v-model="commander"
            slot-scope="{ errors, valid }"
            label="Activity Lead"
            :error-messages="errors"
            :success="valid"
            hint="The participant acting as Activity Lead."
            clearable
            required
            :project="project"
          />
        </ValidationProvider>
      </v-flex>
      <v-flex xs6>
        <project-select v-model="project" />
      </v-flex>
      <v-flex xs6>
        <incident-type-select v-model="incident_type" :project="project" />
      </v-flex>
      <v-flex xs6>
        <incident-severity-select v-model="incident_severity" :project="project" />
      </v-flex>
      <v-flex xs6>
        <incident-priority-select v-model="incident_priority" :project="project" />
      </v-flex>
      <v-flex xs6>
        <v-select
          v-model="status"
          label="Status"
          :items="statuses"
          hint="The status of the IAO."
        />
      </v-flex>
      <v-flex xs6>
        <v-select
          v-model="visibility"
          label="Visibility"
          :items="visibilities"
          hint="The visibilty of the IAO."
        />
      </v-flex>
      <v-flex xs12>
        <v-row>
          <v-col cols="6">
            <date-time-picker-menu label="Reported At" v-model="reported_at" />
          </v-col>
          <v-col cols="6">
            <date-time-picker-menu label="Stable At" v-model="stable_at" />
          </v-col>
        </v-row>
      </v-flex>
      <v-flex xs12>
        <incident-custom-fields v-model="custom_fields" :project="project" />
      </v-flex>
      <v-flex xs12>
        <service-combobox label="Impacted Services" v-model="services" />
      </v-flex>
      <v-flex xs12>
        <case-filter-combobox label="Cases" v-model="cases" />
      </v-flex>
      <v-flex xs12>
        <tag-filter-auto-complete
          label="Tags"
          v-model="tags"
          :project="project"
          model="incident"
          :model-id="id"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { ValidationProvider, extend } from "vee-validate"
import { mapFields } from "vuex-map-fields"
import { required } from "vee-validate/dist/rules"

import CaseFilterCombobox from "@/case/CaseFilterCombobox.vue"
import DateTimePickerMenu from "@/components/DateTimePickerMenu.vue"
import IncidentFilterCombobox from "@/incident/IncidentFilterCombobox.vue"
import IncidentPrioritySelect from "@/incident/priority/IncidentPrioritySelect.vue"
import IncidentSeveritySelect from "@/incident/severity/IncidentSeveritySelect.vue"
import IncidentTypeSelect from "@/incident/type/IncidentTypeSelect.vue"
import ParticipantSelect from "@/incident/ParticipantSelect.vue"
import ProjectSelect from "@/project/ProjectSelect.vue"
import TagFilterAutoComplete from "@/tag/TagFilterAutoComplete.vue"
import IncidentCustomFields from "@/incident/type/IncidentCustomFields.vue"
import ServiceCombobox from "@/service/ServiceCombobox.vue"

extend("required", {
  ...required,
  message: "This field is required",
})

export default {
  name: "IncidentDetailsTab",

  components: {
    CaseFilterCombobox,
    DateTimePickerMenu,
    IncidentFilterCombobox,
    IncidentPrioritySelect,
    IncidentSeveritySelect,
    IncidentTypeSelect,
    ParticipantSelect,
    ProjectSelect,
    TagFilterAutoComplete,
    ValidationProvider,
    IncidentCustomFields,
    ServiceCombobox,
  },

  data() {
    return {
      statuses: ["Active", "Stable", "Closed"],
      visibilities: ["Open", "Restricted"],
    }
  },

  computed: {
    ...mapFields("incident", [
      "selected.cases",
      "selected.commander",
      "selected.created_at",
      "selected.custom_fields",
      "selected.description",
      "selected.id",
      "selected.incident_priority",
      "selected.incident_severity",
      "selected.incident_type",
      "selected.name",
      "selected.project",
      "selected.reported_at",
      "selected.reporter",
      "selected.assignee",
      "selected.resolution",
      "selected.services",
      "selected.stable_at",
      "selected.status",
      "selected.tags",
      "selected.terms",
      "selected.title",
      "selected.visibility",
    ]),
  },
}
</script>
