<!--
.. Mofified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container>
  <v-card>
    <v-list dense>
      <v-list-item class="total-cost-item">
        <v-list-item-content>
          <v-list-item-title class="headline mb-2">
            <v-icon left large color="error">mdi-currency-usd</v-icon>
            Total Cost
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <span class="headline">{{ totalCost | toUSD }}</span>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item v-for="(cost, index) in incident_costs" :key="index">
        <v-list-item-icon v-if="cost.incident_cost_type.editable">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn small icon @click="removeIncidentCost(index)" v-on="on">
                <v-icon color="error">mdi-currency-usd-off</v-icon>
              </v-btn>
            </template>
            <span>Remove Cost</span>
          </v-tooltip>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ cost.incident_cost_type.name }}</v-list-item-title>
          <v-list-item-subtitle>{{ cost.incident_cost_type.description }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>{{ cost.amount | toUSD }}</v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
    </v-list>
    <v-card-actions>
      <incident-cost-input @input="addIncidentCost($event)" />
    </v-card-actions>
  </v-card>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
import { mapMultiRowFields } from "vuex-map-fields";
import IncidentCostInput from "@/incident_cost/IncidentCostInput.vue";

export default {
  name: "IncidentCostsTab",

  components: {
    IncidentCostInput,
  },

  computed: {
    ...mapMultiRowFields("incident", ["selected.incident_costs"]),

    totalCost() {
      const totalCost = this.incident_costs.reduce((accumulator, item) => {
        return accumulator + item.amount;
      }, 0);
      return totalCost;
    },
  },

  methods: {
    ...mapMutations("incident", ["addIncidentCost", "removeIncidentCost"]),
  },
};
</script>

<style scoped>
.headline {
  font-size: 20px;
  font-weight: bold;
}
.mb-2 {
  margin-bottom: 16px;
}

.total-cost-item .v-list-item__title,
.total-cost-item .v-list-item__subtitle {
  line-height: 1.25rem;
}
</style>
