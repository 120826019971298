<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
    <v-combobox :items="items" :label="label" :loading="loading" :menu-props="{ maxHeight: '400' }"
      :search-input.sync="search" @update:search-input="fetchData({ q: $event })" chips clearable deletable-chips
      hide-selected item-text="name" item-value="id" multiple no-filter v-model="service">
      <template v-slot:selection="{ attr, on, item, selected }">
        <v-chip v-bind="attr" :input-value="selected" v-on="on">
          <service-popover :service="item" />
        </v-chip>
      </template>
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              No matching "
              <strong>{{ search }}</strong>".
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-combobox>
  </template>

  <script>
  import ServiceApi from "@/service/api"
  import { cloneDeep, debounce } from "lodash"
  import Service from "@/service/Service.vue"
  import ServicePopover from "./ServicePopover.vue"

  export default {
    name: "ServiceComboBox",
    components: {
      Service,
      ServicePopover,
    },
    props: {
      value: {
        type: Array,
        default: function () {
          return []
        },
      },
      label: {
        type: String,
        default: function () {
          return "Service"
        },
      },
    },

    data() {
      return {
        loading: false,
        items: [],
        search: null,
      }
    },

    computed: {
      service: {
        get() {
          return cloneDeep(this.value)
        },
        set(value) {
          this._services = value.filter((v) => {
            if (typeof v === "string") {
              return false
            }
            return true
          })
          this.$emit("input", this._services)
          this.search = null
        },
      },
    },

    created() {
      this.fetchData({})
    },

    methods: {
      fetchData(filterOptions) {
        this.error = null
        this.loading = "error"
        ServiceApi.getAll(filterOptions).then((response) => {
          this.items = response.data.items
          this.loading = false
        })
      },
      getFilteredData: debounce(function (options) {
        this.fetchData(options)
      }, 500),
    },
  }
  </script>
