<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <div>
    <loading v-if="loading"/>
  <ValidationObserver v-slot="{ invalid, validated }">
    <v-progress-linear v-if="loading" indeterminate color="error"></v-progress-linear>
    <v-navigation-drawer
      app
      right
      clipped
      :width="computedWidth"
      ref="drawer"
      :permanent="$vuetify.breakpoint.mdAndDown"
    >
      <v-progress-linear v-if="loading" indeterminate color="error"></v-progress-linear>
      <v-expansion-panels popout>
        <v-expansion-panel>
          <v-expansion-panel-header style="background-color: #FF1744;">
            <strong>{{ name }}</strong>
            <v-spacer />
          </v-expansion-panel-header>
          <v-expansion-panel-content>
          <v-list-item one-line>
            <v-list-item-content>
              <v-list-item-title class="title">
                <v-row>
                  <v-col cols="auto" class="d-flex align-center">
                    <v-icon small left>mdi-alert-outline</v-icon>
                  {{ name }}
                  </v-col>
                  <v-col cols="auto" class="d-flex align-center">
                    <incident-status :status="status" :id="id" />
                  </v-col>
                  <v-col cols="auto" class="d-flex align-center">
                    <incident-priority
                      :priorityName="incident_priority.name"
                      :priorityColor="incident_priority.color"
                    />
                  </v-col>
                  <v-col cols="auto" class="d-flex align-center">
                    <incident-severity
                      :severityName="incident_severity.name"
                      :severityColor="incident_severity.color"
                    />
                  </v-col>
                  <v-col cols="auto" class="d-flex align-center">
                    <incident-participant :participant="commander" />
                  </v-col>
                  <v-col cols="auto" class="d-flex align-center">
                    <incident-cost-card :incident-costs="incident_costs" />
                  </v-col>
                  <v-col cols="auto" class="d-flex align-center">
                    <v-chip small class="ml-2">
                      <v-icon
                        v-if="visibility == 'Open'"
                        color="success"
                        small
                        class="mr-1"
                        >mdi-eye-check</v-icon
                      >
                      <v-icon
                        v-if="visibility == 'Restricted'"
                        small
                        color="warning"
                        class="mr-1"
                        >mdi-eye-off</v-icon
                      >
                      <span v-if="visibility == 'Open'">Public</span>
                      <span v-if="visibility == 'Restricted'">Private</span>
                    </v-chip>
                  </v-col>
                  <v-col cols="auto" class="d-flex align-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <template v-if="status === 'Active' || status === 'Stable' && conference.weblink">
                          <a :href="conference.weblink" target="_blank" v-bind="attrs" v-on="on">
                            <v-icon
                              v-if="conference.weblink"
                              color="error"
                              class="mr-1"
                            >mdi-video-wireless-outline</v-icon>
                          </a>
                        </template>
                        <template v-else>
                          <v-icon
                            v-if="conference.weblink"
                            color="grey lighten-1"
                            class="mr-1"
                            v-bind="attrs" v-on="on"
                          >mdi-video-wireless-outline</v-icon>
                        </template>
                      </template>
                      <span>Join Incident Conference</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="auto" class="d-flex align-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <template v-if="status === 'Active' || status === 'Stable' && conversation.weblink">
                          <a :href="conversation.weblink" target="_blank" v-bind="attrs" v-on="on">
                            <v-icon
                              v-if="conversation.weblink"
                              color="primary"
                              class="mr-1"
                            >mdi-message-flash-outline</v-icon>
                          </a>
                        </template>
                        <template v-else>
                          <v-icon
                            v-if="conversation.weblink"
                            color="grey lighten-1"
                            class="mr-1"
                            v-bind="attrs" v-on="on"
                          >mdi-message-flash-outline</v-icon>
                        </template>
                      </template>
                      <span>Join Incident Channel</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="auto" class="d-flex align-center">
                  <ai-resolution-summary-dialog :summary="ai_resolution_summary" />
                  </v-col>
                  <v-col cols="auto" class="d-flex align-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :disabled="status === 'Closed'"
                          @click="showRun({ type: 'incident', data: selected })"
                          v-bind="attrs"
                          v-on="on"
                          icon
                          color="tips"
                        >
                          <v-icon>mdi-cog-sync-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Run Workflow</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="auto" class="d-flex align-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :disabled="status === 'Closed'"
                          @click="showReportDialog(selected)"
                          v-bind="attrs"
                          v-on="on"
                          icon
                          color="primary"
                        >
                          <v-icon>mdi-send-circle-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Create Comms Report</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-list-item-title>
              <v-list-item-subtitle>
                <span class="timeline-container">
                  <v-row class="timeline">
                    <!-- Reported State -->
                    <v-col cols="2" class="timeline-item">
                      <div class="timeline-content" :class="{'active': status === 'Active'}">
                        <v-icon
                          large
                          :color="reported_at ? 'secondary' : 'grey'"
                          >
                          mdi-state-machine
                        </v-icon>
                        <div class="d-flex flex-column">
                          <span>Reported</span>
                          <div>
                            {{ reported_at | formatRelativeDate }}
                          </div>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="2" class="timeline-item">
                      <div class="timeline-content" :class="{'active': status === 'Stable'}">
                        <v-icon
                          large
                          :color="stable_at ? 'primary' : 'grey'"
                          class="d-flex align-center"
                        >
                          mdi-state-machine
                        </v-icon>
                        <div class="d-flex flex-column">
                          <span>Stable</span>
                          <div>
                            {{ stable_at | formatRelativeDate }}
                          </div>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-switch
                                  v-model="isStable"
                                  :disabled="status == 'Closed' || status == 'Stable'"
                                  @change="markStable(id)"
                                  :loading="updateLoading"
                                  color="primary"
                                  hide-details
                                  class="mt-1"
                                ></v-switch>
                              </div>
                            </template>
                            <span>Toggle to mark as Stable</span>
                          </v-tooltip>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="2" class="timeline-item">
                      <div class="timeline-content" :class="{'active': status === 'Closed'}">
                        <v-icon
                          large
                          :color="closed_at ? 'success' : 'grey'"
                          class="d-flex align-center"
                        >
                          mdi-state-machine
                        </v-icon>
                        <div class="d-flex flex-column">
                          <span>Closed</span>
                          <div>
                            {{ closed_at | formatRelativeDate }}
                          </div>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-switch
                                  v-model="isClosed"
                                  :disabled="status === 'Closed'"
                                  @change="markClosed(id)"
                                  :loading="updateLoading"
                                  color="primary"
                                  hide-details
                                  class="mt-1"
                                ></v-switch>
                              </div>
                            </template>
                            <span>Toggle to mark as Closed</span>
                          </v-tooltip>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="2" class="timeline-item">
                      <div class="timeline-content">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-if="status == 'Stable' || status == 'Closed'"
                              @click="createPostmortemReport()"
                              v-on="on"
                              class="mt-7"
                              icon
                            >
                              <v-icon left color="info">mdi-plus</v-icon>
                              <v-icon left color="info">mdi-strategy</v-icon>
                              <v-icon left color="info">mdi-book-open-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Generate After-Activity Review (PIR) Draft</span>
                        </v-tooltip>
                      </div>
                    </v-col>
                    <v-col cols="2" class="timeline-item">
                      <div class="timeline-content">
                        <v-btn
                        @click="fetchDetails"
                        class="mt-7"
                        >
                          <v-icon color="secondary">mdi-refresh</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-btn
              icon
              color="primary"
              :loading="loading"
              :disabled="invalid || !validated"
              @click="save()"
            >
              <v-icon>save</v-icon>
            </v-btn>
            <v-btn icon color="secondary" @click="closeEditSheet">
              <v-icon>close</v-icon>
            </v-btn>
          </v-list-item>
        </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-tabs color="error" fixed-tabs v-model="tab">
        <v-tab key="details">
          <v-icon class="mr-2">mdi-axis-arrow-info</v-icon> Details
        </v-tab>
        <v-tab key="resources">
          <v-icon class="mr-2">mdi-folder-star-multiple-outline</v-icon> Resources
        </v-tab>
        <v-tab key="participants">
          <v-icon class="mr-2">mdi-microsoft-teams</v-icon> Participants
        </v-tab>
        <v-tab key="timeline">
          <v-icon class="mr-2">mdi-timeline-text-outline</v-icon> Timeline
        </v-tab>
        <v-tab key="similar_incidents">
          <v-icon class="mr-2">mdi-vector-intersection</v-icon> Similar
        </v-tab>
        <v-tab key="workflows"> <v-icon class="mr-2">mdi-cog-sync-outline</v-icon> Workflows </v-tab>
        <v-tab key="incident_graph">
          <v-icon class="mr-2">mdi-graph-outline</v-icon> Graph
        </v-tab>
        <v-tab key="checklist">
          <v-icon class="mr-2">mdi-source-branch-check</v-icon> Steps
        </v-tab>
        <v-tab key="tasks"> <v-icon class="mr-2">mdi-file-tree</v-icon> Tasks </v-tab>
        <v-tab key="costs"> <v-icon class="mr-2">mdi-currency-usd</v-icon> Costs </v-tab>
      </v-tabs>

      <workflow-run-modal />

      <v-tabs-items v-model="tab">
        <v-tab-item key="details">
          <incident-details-tab />
        </v-tab-item>
        <v-tab-item key="resources">
          <incident-resources-tab />
        </v-tab-item>
        <v-tab-item key="participants">
          <incident-participants-tab
            :selected="selected"
            v-model="participants"
            v-if="selected.participants"
          />
        </v-tab-item>
        <v-tab-item key="timeline">
          <incident-timeline-tab />
        </v-tab-item>
        <v-tab-item key="similar_incidents">
          <similar-incidents-tab />
        </v-tab-item>
        <v-tab-item key="workflow_instances">
          <workflow-instance-tab v-model="workflow_instances" />
        </v-tab-item>
        <v-tab-item key="incident_graph">
          <incident-graph-tab />
        </v-tab-item>
        <v-tab-item key="checklist">
          <incident-checklist-tab />
        </v-tab-item>
        <v-tab-item key="tasks">
          <incident-tasks-tab />
        </v-tab-item>
        <v-tab-item key="costs">
          <incident-costs-tab />
        </v-tab-item>
      </v-tabs-items>
    </v-navigation-drawer>
  </ValidationObserver>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"
import { ValidationObserver } from "vee-validate"
import moment from "moment"

import IncidentCostsTab from "@/incident/CostsTab.vue"
import IncidentDetailsTab from "@/incident/DetailsTab.vue"
import IncidentParticipantsTab from "@/incident/participant/IncidentParticipantsTab.vue"
import IncidentResourcesTab from "@/incident/ResourcesTab.vue"
import IncidentTasksTab from "@/incident/TasksTab.vue"
import IncidentTimelineTab from "@/incident/TimelineTab.vue"
import WorkflowInstanceTab from "@/workflow/WorkflowInstanceTab.vue"
import WorkflowRunModal from "@/workflow/RunModal.vue"
import IncidentChecklistTab from "@/incident/CheckListTab.vue"
import SimilarIncidentsTab from "@/incident/SimilarIncidentsTab.vue"
import IncidentParticipant from "@/incident/Participant.vue"
import IncidentCostCard from "@/incident_cost/IncidentCostCard.vue"
import IncidentPriority from "@/incident/priority/IncidentPriority.vue"
import IncidentSeverity from "@/incident/severity/IncidentSeverity.vue"
import IncidentStatus from "@/incident/status/IncidentStatus.vue"
import IncidentGraphTab from "@/incident/IncidentGraphTab.vue"
import AiResolutionSummaryDialog from '@/incident/AiResolutionSummaryDialog.vue'
import Loading from "@/components/Loading.vue"

export default {
  name: "IncidentEditSheet",

  components: {
    IncidentCostsTab,
    IncidentDetailsTab,
    IncidentParticipantsTab,
    IncidentResourcesTab,
    IncidentTasksTab,
    IncidentTimelineTab,
    ValidationObserver,
    WorkflowInstanceTab,
    WorkflowRunModal,
    IncidentChecklistTab,
    SimilarIncidentsTab,
    IncidentParticipant,
    IncidentCostCard,
    IncidentPriority,
    IncidentSeverity,
    IncidentStatus,
    IncidentGraphTab,
    AiResolutionSummaryDialog,
    Loading,
  },

  data() {
    return {
      tab: null,
      navigation: {
        width: 2400,
        borderSize: 3,
        minWidth: 400,
      },
      isStable: false,
      isClosed: false,
    }
  },

  computed: {
    ...mapFields("incident", [
      "selected",
      "selected.ai_resolution_summary",
      "selected.commander",
      "selected.conference",
      "selected.conversation",
      "selected.id",
      "selected.name",
      "selected.project",
      "selected.status",
      "selected.incident_priority",
      "selected.incident_severity",
      "selected.incident_type",
      "selected.reported_at",
      "selected.stable_at",
      "selected.closed_at",
      "selected.loading",
      "selected.participants",
      "selected.workflow_instances",
      "dialogs.showEditSheet",
      "selected.incident_costs",
      "selected.visibility",
      "updateLoading",
    ]),
    selectedId() {
      console.log(this.selected)
      return this.selected ? this.selected.id : null
    },
    joinColor() {
      if (this.status === "Active") {
        return "error"
      } else if (this.status === "Stable") {
        return "warning"
      } else {
        return "grey"
      }
    },
    computedWidth() {
    // Get the full screen width and subtract 80px (width of mini nav)
      return this.$vuetify.breakpoint.width - 55;
    },
  },

  created() {
    this.fetchDetails()
  },

  watch: {
    "$route.params.name": function () {
      this.fetchDetails()
    },
    $route: {
      immediate: true,
      handler: function (newVal) {
        if (newVal.meta && newVal.meta.showTimeline) {
          this.tab = 3
        }
      },
    },
  },
  filters: {
    formatRelativeDate(value) {
      if (value) {
        return moment(value).fromNow()
      }
      return value;
    },
  },

  methods: {
    statusIcon(status) {
      return this.status === status ? 'mdi-check' : '';
    },
    fetchDetails() {
      this.getDetails({ name: this.$route.params.name })
    },
    createPostmortemReport() {
      this.$store.dispatch("incident/createPostmortemReport", { incidentId: this.id })
    },
    ...mapActions("incident", ["save", "getDetails", "closeEditSheet", "markStable", "markClosed", "showReportDialog"]),
    ...mapActions("workflow", ["showRun"]),
    setBorderWidth() {
      const drawerBorder = this.$refs.drawer.$el.querySelector(".v-navigation-drawer__border")
      drawerBorder.style.width = this.navigation.borderSize + "px"
      drawerBorder.style.cursor = "ew-resize"
      drawerBorder.style.backgroundColor = "#E4E4E4"
    },
    setEvents() {
      const borderSize = this.navigation.borderSize
      const minWidth = this.navigation.minWidth
      const drawerElement = this.$refs.drawer.$el
      const drawerBorder = drawerElement.querySelector(".v-navigation-drawer__border")
      function resize(e) {
        document.body.style.cursor = "ew-resize"
        let f = document.body.scrollWidth - e.clientX
        if (f < minWidth) {
          f = minWidth
        }
        drawerElement.style.width = f + "px"
      }
      drawerBorder.addEventListener(
        "mouseover",
        () => {
          drawerBorder.style.backgroundColor = "error"
        },
        false
      )
      drawerBorder.addEventListener(
        "mouseout",
        () => {
          drawerBorder.style.backgroundColor = "#E4E4E4"
        },
        false
      )
      drawerBorder.addEventListener(
        "mousedown",
        (e) => {
          if (e.offsetX < borderSize) {
            drawerElement.style.transition = "initial"
            document.addEventListener("mousemove", resize, false)
          }
        },
        false
      )
      document.addEventListener(
        "mouseup",
        () => {
          drawerElement.style.transition = ""
          this.navigation.width = drawerElement.style.width
          document.body.style.cursor = ""
          document.removeEventListener("mousemove", resize, false)
        },
        false
      )
    },
  },

  mounted() {
    this.setBorderWidth()
    this.setEvents()
  },
}
</script>

<style scoped>

.timeline-container {
  overflow-x: auto;
  white-space: nowrap;
}

.timeline-item {
  display: inline-block;
  text-align: center;
}

.timeline-content {
  padding: 5px;
  border-radius: 10px;
  transition: transform 0.3s ease;
  font-size: 10px;
}

.timeline-content.active {
  transform: scale(1.0);
}

.timeline-item:not(:last-child) {
  position: relative; /* Required for absolute positioning of the pseudo-element */
}

.timeline-item:not(:last-child):after {
  content: ''; /* Required for the pseudo-element to show up */
  position: absolute;
  top: 50%; /* Align the line in the middle vertically */
  right: -25%; /* Extend the line towards the next item; adjust based on your layout */
  width: 50%; /* Length of the line; adjust based on your gap between items */
  border-top: 2px solid lightblue;
  transform: translateY(-50%);
}
</style>
