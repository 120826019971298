<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
    <v-dialog v-model="display" max-width="450px">
      <template #activator="{ on }">
        <v-badge
          :value="numFilters"
          bordered
          overlap
          color="tips"
          :content="numFilters"
          class="mr-3"
        >
          <v-btn small color="primary" v-on="on">
            <v-icon small left class="mr-1">mdi-filter</v-icon> Filter
          </v-btn>
        </v-badge>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Timeline Filters</span>
        </v-card-title>
        <v-card-subtitle class="subtitle_top">
          Only show the following types of events
        </v-card-subtitle>
        <v-list dense>
          <v-list-item>
            <v-list-item-content class="pl-5 pt-5">
              <v-row align="center">
                <v-checkbox v-model="allSelected" @change="toggleAll" color="tips" />
                <span class="ml-2">Select/Deselect All</span>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="pl-5 pt-5">
              <v-row align="center">
                <v-simple-checkbox v-model="local_filters.assessment_updates" color="tips" />
                <v-icon left class="mr-2">mdi-priority-high</v-icon>
                Assessment updates
              </v-row>
              <span class="text-caption ml-5">Priority, severity, and status</span>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="pl-5 pt-5">
              <v-row align="center">
                <v-simple-checkbox v-model="local_filters.user_curated_events" color="tips" />
                <v-icon left class="mr-2">mdi-text-account</v-icon>
                User-curated events
              </v-row>
              <span class="text-caption ml-5">Custom events and imported messages</span>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="pl-5 pt-5">
              <v-row align="center">
                <v-simple-checkbox v-model="local_filters.field_updates" color="tips" />
                <v-icon left class="mr-2">mdi-subtitles-outline</v-icon>
                Field updates
              </v-row>
              <span class="text-caption ml-5">Fields like title, description, tags, type, etc.</span>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="pl-5 pt-5">
              <v-row align="center">
                <v-simple-checkbox v-model="local_filters.step_updates" color="tips" />
                <v-icon left class="mr-2">mdi-step-forward</v-icon>
                Step updates
              </v-row>
              <span class="text-caption ml-5">Step changes in the incident workflow</span>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="pl-5 pt-5">
              <v-row align="center">
                <v-simple-checkbox v-model="local_filters.task_updates" color="tips" />
                <v-icon left class="mr-2">mdi-calendar-check</v-icon>
                Task updates
              </v-row>
              <span class="text-caption ml-5">Task creation, deletion, and updates</span>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="pl-5 pt-5">
              <v-row align="center">
                <v-simple-checkbox v-model="local_filters.context_updates" color="tips" />
                <v-icon left class="mr-2">mdi-clipboard-text-outline</v-icon>
                Context updates
              </v-row>
              <span class="text-caption ml-5">Context updates in the incident</span>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="pl-5 pt-5">
              <v-row align="center">
                <v-simple-checkbox v-model="local_filters.participant_updates" color="tips" />
                <v-icon left class="mr-2">mdi-account-outline</v-icon>
                Participant updates
              </v-row>
              <span class="text-caption ml-5">Added/removed participants and role changes</span>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="pl-5 pt-5">
              <v-row align="center">
                <v-simple-checkbox v-model="local_filters.ai_generated_events" color="tips" />
                <v-icon left class="mr-2">mdi-creation</v-icon>
                AI-generated events
              </v-row>
              <span class="text-caption ml-5">Events generated by Dispatch's AI</span>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="pl-5 pt-5">
              <v-row align="center">
                <v-simple-checkbox v-model="local_filters.notification_events" color="tips" />
                <v-icon left class="mr-2">mdi-bell-outline</v-icon>
                Notification events
              </v-row>
              <span class="text-caption ml-5">Events generated by UnStruct's notification system</span>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="pl-5 pt-5">
              <v-row align="center">
                <v-simple-checkbox v-model="local_filters.resource_updates" color="tips" />
                <v-icon left class="mr-2">mdi-folder-multiple-outline</v-icon>
                Resource updates
              </v-row>
              <span class="text-caption ml-5">Resource creation, deletion, and updates</span>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
          <v-list-item-content class="pl-5 pt-5">
              <v-row align="center">
                <v-simple-checkbox v-model="local_filters.conversation_events" color="tips" />
                <v-icon left class="mr-2">mdi-message-text-outline</v-icon>
                Conversation events
              </v-row>
              <span class="text-caption ml-5">Conversations during incidents</span>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="pl-5 pt-5">
              <v-row align="center">
                <v-simple-checkbox v-model="local_filters.other_events" color="tips" />
                <v-icon left class="mr-2">mdi-monitor-star</v-icon>
                Other events
              </v-row>
              <span class="text-caption ml-5">System events, resource creation, etc.</span>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="applyFilters()"> Apply Filters </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>

  <script>
  import { sum } from "lodash"
  import { mapFields } from "vuex-map-fields"
  function invertBooleanValues(obj) {
    return Object.keys(obj).reduce((acc, key) => {
      acc[key] = !obj[key]
      return acc
    }, {})
  }
  export default {
    name: "IncidentTimelineFilterDialog",
    props: {
      projects: {
        type: Array,
        default: function () {
          return []
        },
      },
    },
    data() {
      return {
        display: false,
        local_filters: {
          field_updates: false,
          assessment_updates: true,
          user_curated_events: true,
          participant_updates: false,
          other_events: false,
          notification_events: true,
          ai_generated_events: false,
          resource_updates: false,
          conversation_events: false,
          step_updates: true,
          task_updates: true,
          context_updates: false,

        },
        allSelected: false,
      }
    },
    computed: {
      ...mapFields("incident", ["timeline_filters"]),
      numFilters: function () {
        return sum(Object.values(this.timeline_filters))
      },
    },
    methods: {
      applyFilters() {
        this.timeline_filters = invertBooleanValues(this.local_filters)
        this.display = false
      },
      toggleAll() {
        const newState = this.allSelected;
        this.local_filters.assessment_updates = newState;
        this.local_filters.user_curated_events = newState;
        this.local_filters.field_updates = newState;
        this.local_filters.participant_updates = newState;
        this.local_filters.ai_generated_events = newState;
        this.local_filters.notification_events = newState;
        this.local_filters.resource_updates = newState;
        this.local_filters.conversation_events = newState;
        this.local_filters.other_events = newState;
        this.local_filters.step_updates = newState;
        this.local_filters.task_updates = newState;
        this.local_filters.context_updates = newState;
      },
    },
    created() {
      this.$watch(
        (vm) => [vm.display],
        () => {
          if (this.display) {
            // copy actual to local on dialog show
            this.local_filters = invertBooleanValues(this.timeline_filters)
          }
        }
      )
    },
  }
  </script>

  <style scoped lang="scss">
  .subtitle_top {
    margin-top: -10px;
  }
  </style>
