<!--
.. Modified by Kishore Jalleda
.. Full list of modifications at https://github.com/unstructai
.. Copyright: (c) 2023 Kishore Jalleda
.. Author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-card flat class="date-chip-group pa-2">
    <v-chip-group
      v-model="selectedDateTime"
      @change="handleDateChange"
      active-class="chip-active"
      mandatory
      show-arrows
    >
      <v-chip
        v-for="option in options"
        :key="option.value"
        :value="option.value"
        filter
        outlined
        class="chip"
      >
        <v-icon left small>{{ option.icon }}</v-icon>
        {{ option.label }}
      </v-chip>
    </v-chip-group>
  </v-card>
</template>

<script>
export default {
  name: "DateChipGroupRelative",
  props: {
    value: {
      type: Number,
      default: 30,
    },
  },
  data() {
    return {
      selectedDateTime: this.value,
      options: [
        { value: 1, label: "Last 24 hours", icon: "mdi-clock-outline" },
        { value: 3, label: "Last 3 days", icon: "mdi-calendar-range" },
        { value: 7, label: "Last week", icon: "mdi-calendar-week" },
        { value: 30, label: "Last month", icon: "mdi-calendar-month" },
        { value: 365, label: "Last year", icon: "mdi-calendar-year" },
      ],
    };
  },
  methods: {
    handleDateChange() {
      this.$emit("input", this.selectedDateTime);
    },
  },
  watch: {
    value(newValue) {
      this.selectedDateTime = newValue;
    },
  },
};
</script>

<style scoped>
.date-chip-group {
  border-radius: 15px;
  padding: 8px;
}

.chip {
  margin: 4px;
  transition: all 0.3s ease;
}

.chip:hover {
  background-color: rgba(0, 198, 255, 0.2) !important;
  box-shadow: 0 0 15px rgba(0, 198, 255, 0.3);
}


@media (max-width: 600px) {
  .v-chip-group {
    justify-content: flex-start;
    overflow-x: auto;
  }

  .v-chip-group::-webkit-scrollbar {
    height: 4px;
  }

  .v-chip-group::-webkit-scrollbar-thumb {
    background: rgba(0, 198, 255, 0.3);
    border-radius: 2px;
  }

  .v-chip-group::-webkit-scrollbar-track {
    background: rgba(0, 198, 255, 0.1);
  }
}
</style>
