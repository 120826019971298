<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container fluid>
  <div>
    <v-btn
      color="primary"
      @click="fetchSimilarIncidents"
      class="mb-3 ml-5 mt-5"
      v-tooltip.bottom="'This feature is in Beta'"
      small
    >
    <v-icon left>mdi-vector-intersection</v-icon>
      Get Similar IAOs
    </v-btn>
    <v-chip small label color="warning" class="ml-3 mb-5">
      <v-icon left x-small>mdi-creation</v-icon>
      Beta</v-chip>
    <v-progress-circular v-if="loading" indeterminate color="error"></v-progress-circular>

    <div v-if="!loading && !similarIncidents.length" class="ml-5 mt-5">
      <v-alert outlined type="info">
        No similar IAOs found at the moment. Please try again later.
      </v-alert>
    </div>

    <v-expansion-panels multiple v-if="similarIncidents.length">
      <v-expansion-panel v-for="incidentData in similarIncidents" :key="incidentData.id">
        <v-expansion-panel-header>
          <strong>
            <v-icon left color="primary">mdi-domain</v-icon>
            {{ incidentData.name }}
          </strong>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-if="incidentData && incidentData.similarity_score"
                v-bind="attrs"
                v-on="on"
                large
              >
               <v-icon large left>mdi-vector-intersection</v-icon>
                Similarity Score: {{ incidentData.similarity_score.toFixed(2) }}
              </v-chip>
            </template>
            <div>
              J(A,B) = <br>
              <span style="font-size: 1.2em; font-weight: bold;">∣A∩B∣ / ∣A∪B∣</span><br>
              Where J(A,B) is the Jaccard similarity between sets A and B.
            </div>
          </v-tooltip>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p><strong>
            <v-icon left color="primary">mdi-calendar-clock</v-icon>
            Created:</strong> {{ incidentData.created_at | formatRelativeDate }}</p>
          <p><strong>
            <v-icon left color="primary">mdi-alert-octagon</v-icon>
            Title:</strong> {{ incidentData.title }}</p>
          <p><strong>
            <v-icon left color="primary">mdi-text</v-icon>
            Description:</strong> {{ incidentData.description }}</p>
          <p><strong>
            <v-icon left color="primary">mdi-traffic-light</v-icon>
            Status: </strong> <incident-status :status="incidentData.status" :id="incidentData.id" /></p>
          <div v-if="incidentData.resolution" class="mb-2">
                <v-alert
                  type="success"
                  dense
                  outlined
                  border="left"
                  colored-border
                  class="mb-2"
                  :icon="false"
                >
                  <strong>Resolution:</strong> {{ incidentData.resolution }}
                </v-alert>
          </div>
          <div class="mb-2">
                <v-alert
                  type="primary"
                  dense
                  outlined
                  border="left"
                  colored-border
                  class="mb-2"
                  :icon="false"
                >
                  <strong>
                    <v-icon left large color="primary">mdi-creation</v-icon>
                    AI Summary:
                  </strong>
                  <p>
                    <span class="ai-resolution-summary">
                    <span v-html="incidentData.ai_resolution_summary || 'No AI Summary available.'"></span>
                  </span>
                  </p>
                </v-alert>
          </div>
          <div class="actions-container">
            <v-btn
              class="view-incident-button"
              :to="{
                name: 'IncidentTableEdit',
                params: { organization: organization, name: incidentData.name },
              }"
            >
              View IAO
            </v-btn>
            <v-btn icon @click="onLike">
              <v-icon>mdi-thumb-up-outline</v-icon>
            </v-btn>
            <!-- Dislike Button -->
            <v-btn icon @click="onDislike">
              <v-icon>mdi-thumb-down-outline</v-icon>
          </v-btn>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
  </v-container>
</template>

  <script>
import { mapActions } from "vuex"
import { mapFields } from "vuex-map-fields"
import IncidentStatus from "@/incident/status/IncidentStatus.vue"

export default {
  name: "SimilarIncidentsTab",
  components: {
    IncidentStatus,
  },
  data() {
    return {
      similarIncidents: [],
      loading: false,
    }
  },
  computed: {
    ...mapFields("incident", ["selected.id"]),
    organization() {
      return this.$route.params.organization
    },
  },
  mounted() {
    this.fetchSimilarIncidents()
  },
  methods: {
    fetchSimilarIncidents() {
      this.loading = true
      this.getSimilar({ incidentId: this.id, limit: 5 })
        .then((data) => {
          this.similarIncidents = data.items
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    onLike() {
      this.$store.dispatch("incident/likeIncident", { incidentId: this.id })
    },
    onDislike() {
      this.$store.dispatch("incident/dislikeIncident", { incidentId: this.id })
    },
    ...mapActions("incident", ["getSimilar"]),
  },
}
</script>

  <style scoped>

.v-expansion-panel-content {
  border-top: 1px solid #eee;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.ai-resolution-summary {
  padding: 5px;
  border-radius: 4px;
}

.view-incident-button {
  margin-top: 10px;
}

.actions-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
