<template>
    <v-dialog v-model="showStepAiSummaryDialog" max-width="600px">
        <v-card>
        <v-card-title>
            <span class="headline">
                <v-icon left>mdi-creation</v-icon>
                AI Summary for Incident Step
            </span>
        </v-card-title>
        <v-card-text>
           <span v-html="stepAiSummary"></span>
        </v-card-text>
        <v-card-actions>
            <v-btn icon @click="onLike">
                <v-icon>mdi-thumb-up-outline</v-icon>
            </v-btn>
            <v-btn icon @click="onDislike">
                <v-icon>mdi-thumb-down-outline</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="showStepAiSummaryDialog = false">Close</v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
export default {
    name: "StepAiSummaryDialog",
    data() {
        return {}
    },
    props: {
        stepAiSummary: {
            type: String,
            required: true
        },
    },
    computed: {
        ...mapFields('incident', ["dialogs.showStepAiSummaryDialog"]),
    },
    methods: {
        onLike() {
            this.$emit('like');
            this.showStepAiSummaryDialog = false;
        },
        onDislike() {
            this.$emit('dislike');
            this.showStepAiSummaryDialog = false;
        }
    }
}
</script>
