<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container fluid>
  <div>
    <div v-if="steps && steps.length">
      <div class="checklist-container" :class="{ 'animated': showCongratulationsAnimation }">
        <v-card class="my-1 ml-6 mr-6" outlined>
          <v-card-title class="d-flex justify-space-between align-center">
            <div>
              <v-icon left>mdi-target-variant</v-icon>
              Interactive Activity Object (IAO) Outcome Steps
              <v-icon
              large
              :color="allStepsCompleted ? 'success' : 'warning'"
              right>
              mdi-check-all</v-icon>
            </div>
            <div>
              <v-badge
                v-if="steps.length"
                :value="completedStepsCount"
                :content="`${completedStepsCount} / ${steps.length}`"
                overlap
                class="mr-8"
                :color="allStepsCompleted ? 'success' : 'warning'"
              ></v-badge>
            </div>
          </v-card-title>
        </v-card>
        <v-row class="ml-6 mr-6">
          <template v-for="(step, index) in steps">
            <v-col cols="12" md="6" lg="6" :key="'step-' + index">
              <v-card outlined :key="'step-card-' + index" :class="{ 'completed': step.done }" class="mb-3 d-flex flex-column ma-4">
                <v-card-title class="d-flex justify-space-between align-center">
                  <span>
                    {{ step.name }}
                  </span>
                  <v-chip
                    v-if="!step.done"
                  >
                    <v-icon left>mdi-timer-outline</v-icon>
                    {{ dueIn(step) }}
                  </v-chip>
                  <v-chip
                    :color="getSLAChipColor(step)"
                  >
                    <v-icon left>mdi-timer-sand</v-icon>
                    {{ stepCompletionTime(step) }}
                  </v-chip>
                </v-card-title>
                <v-card-text>
                  <div>
                    <v-icon small>mdi-traffic-light</v-icon>
                    Status:
                    <v-icon small left :color="getSLAChipColor(step)">
                      {{ step.done ? 'mdi-code-tags-check' : 'mdi-close' }}
                    </v-icon>
                  </div>
                  <div>
                    <v-icon small>mdi-target-variant</v-icon>
                    SLO: {{ step.sla }} mins
                  </div>
                  <div v-if="!step.done">
                    <v-icon small>mdi-timer-sand</v-icon>
                    <v-progress-linear
                      :value="slaProgress(step)"
                      :color="getSLAChipColor(step)"
                    ></v-progress-linear>
                  </div>
                  <div>
                    <v-icon small>mdi-flag-checkered</v-icon>
                    SLO Met: <v-icon small :color="getSLAChipColor(step)">{{ step.sla_met ? 'mdi-' : 'mdi-close' }}</v-icon>
                  </div>
                  <div>
                    <v-icon small>mdi-reminder</v-icon>
                    Remind Commander on SLO Miss: <v-icon small :color="step.remind_commander_on_sla_miss ? 'info' : 'info'">{{ step.remind_commander_on_sla_miss ? 'mdi-bell-outline' : 'mdi-bell-off-outline' }}</v-icon></div>
                  <div>
                    <v-icon small left>mdi-list-status</v-icon>
                    Completed: {{ getElapsedTime(step.time_completed) }}
                  </div>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn icon
                        v-on="on"
                        @click="toggleStep(step)"
                        :loading="stepLoading[step.id]"
                      >
                        <v-icon>{{ step.done ? 'mdi-undo-variant' : 'mdi-check' }}</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ step.done ? 'Reset Step' : 'Complete Step' }}</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn icon
                        v-on="on"
                        @click="toggleReminder(step)"
                        :loading="stepLoading[step.id]"
                      >
                        <v-icon>{{ step.remind_commander_on_sla_miss ? 'mdi-bell-off-outline' : 'mdi-bell-outline' }}</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ step.remind_commander_on_sla_miss ? 'Disable Reminder' : 'Enable Reminder' }}</span>
                  </v-tooltip>
                </v-card-actions>
              </v-card>
            </v-col>
          </template>
        </v-row>
        <div class="congratulatory-note" v-if="showCongratulationsAnimation">
          <v-icon class="mr-2">mdi-firework</v-icon>
          Congratulations! You've completed the checklist!
        </div>
      </div>
    </div>
    <div v-else>
      <p class="text-center">No steps have been created for this incident.</p>
    </div>
  </div>
  </v-container>
</template>



<script>
import { get } from 'lodash';
import { update } from 'lodash';
import { mapFields } from 'vuex-map-fields';

export default {
  data() {
    return {
      showCongratulationsAnimation: false,
      showSuccessMessage: false,
      successMessage: '',
    };
  },

  computed: {
    ...mapFields('incident', [
      'selected.incident_type', 'selected.steps', 'selected.created_at', 'selected.id', 'stepLoading', 'slaReminderLoading',
    ]),

    allStepsCompleted() {
      return this.steps.every((step) => step.done);
    },
    completedStepsCount() {
      return this.steps.filter((step) => step.done).length;
    },

  },

  methods: {
    // TODO - this should be moved to a vuex action and mutation to keep the component clean

    // calculate the time between the incident creation and the step completion. Return the time in minutes, hours or days
    stepCompletionTime(step) {
      if (!step.time_completed) {
        return 'Not yet';
      }

      const incidentCreatedAt = new Date(this.created_at);
      const stepCompletedAt = new Date(step.time_completed);
      const diffInMs = stepCompletedAt - incidentCreatedAt;
      const diffInMinutes = Math.floor(diffInMs / 60000);
      const diffInHours = Math.floor(diffInMs / 3600000);

      if (diffInMinutes < 60) {
        return `${diffInMinutes} mins`;
      } else if (diffInHours < 24) {
        return `${diffInHours} hrs`;
      } else {
        const diffInDays = Math.floor(diffInMs / 86400000);
        return `${diffInDays} days`;
      }
    },
    async toggleStep(step) {
      const updatedStep = JSON.parse(JSON.stringify(step)); // deep copy
      updatedStep.done = !updatedStep.done;
      if (updatedStep.done) {
        updatedStep.time_completed = new Date().toISOString();
        const incidentCreatedAt = new Date(this.created_at);
        const slaTimeInMs = updatedStep.sla * 60000;
        const slaDeadline = new Date(incidentCreatedAt.getTime() + slaTimeInMs);
        const currentTime = new Date();
        if (currentTime <= slaDeadline) {
          updatedStep.sla_met = true;
        } else {
          updatedStep.sla_met = false;
        }
      } else {
        updatedStep.time_completed = null;
        updatedStep.sla_met = false;
      }

      try {
        await this.$store.dispatch('incident/updateStep', {
          incidentId: this.id,
          stepId: step.id,
          step: updatedStep,
        });
      } catch (error) {
        console.error('An error occurred while updating the step:', error);
      }
    },

    async toggleReminder(step) {
      const updatedStep = JSON.parse(JSON.stringify(step)); // deep copy
      updatedStep.remind_commander_on_sla_miss = !updatedStep.remind_commander_on_sla_miss;
      try {
        await this.$store.dispatch('incident/updateStep', {
          incidentId: this.id,
          stepId: step.id,
          step: updatedStep,
        });
      } catch (error) {
        console.error('An error occurred while updating the step:', error);
      }
    },

    dueIn(step) {
      const incidentCreatedAt = new Date(this.created_at);
      const slaTimeInMs = step.sla * 60000;
      const slaDeadline = new Date(incidentCreatedAt.getTime() + slaTimeInMs);
      const currentTime = new Date();
      const diffInMs = slaDeadline - currentTime;
      const diffInMinutes = Math.floor(diffInMs / 60000);
      const diffInHours = Math.floor(diffInMs / 3600000);

      if (diffInMinutes < 60) {
        return `${diffInMinutes} mins`;
      } else if (diffInHours < 24) {
        return `${diffInHours} hrs`;
      } else {
        const diffInDays = Math.floor(diffInMs / 86400000);
        return `${diffInDays} days`;
      }
    },

    slaProgress(step) {
      const incidentCreatedAt = new Date(this.created_at);
      const slaTimeInMs = step.sla * 60000;
      const slaDeadline = new Date(incidentCreatedAt.getTime() + slaTimeInMs);
      const currentTime = new Date();
      const diffInMs = currentTime - incidentCreatedAt;
      const slaProgress = (diffInMs / slaTimeInMs) * 100;
      return slaProgress;
    },

    notOverdue(step) {
      const incidentCreatedAt = new Date(this.created_at);
      const slaTimeInMs = step.sla * 60000;
      const slaDeadline = new Date(incidentCreatedAt.getTime() + slaTimeInMs);
      const currentTime = new Date();
      return currentTime <= slaDeadline;
    },

    getSLAChipColor(step) {
      if (step.done) {
        return 'primary';
      } else {
        const progressPercentage = this.slaProgress(step);
        if (progressPercentage < 75) {
          return 'primary';
        } else {
          return 'warning';
        }
      }
    },

    getElapsedTime(timeCompleted) {
      if (!timeCompleted) {
        return 'Not yet';
      }

      const now = new Date();
      const completedDate = new Date(timeCompleted);
      const diffInMs = now - completedDate;
      const diffInMinutes = Math.floor(diffInMs / 60000);
      const diffInHours = Math.floor(diffInMs / 3600000);

      if (diffInMinutes < 60) {
        return `${diffInMinutes} mins ago`;
      } else if (diffInHours < 24) {
        return `${diffInHours} hrs ago`;
      } else {
        const diffInDays = Math.floor(diffInMs / 86400000);
        return `${diffInDays} days ago`;
      }
    },
    displaySuccessMessage(message) {
      this.successMessage = message;
      this.showSuccessMessage = true;
      setTimeout(() => {
        this.showSuccessMessage = false;
      }, 3000);
    },
  },
  watch: {
    allStepsCompleted(newVal) {
      if (newVal) {
        this.showCongratulationsAnimation = true;
        setTimeout(() => {
          this.showCongratulationsAnimation = false;
        }, 3000);
      }
    },
  },
};
</script>

<style scoped>
@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
}

.bounce {
  animation: bounce 0.5s ease-in-out;
  animation-iteration-count: 1;
}
.checklist-table {
  width: 100%;
  border-collapse: collapse;
}


.checklist-table .divider-row td {
  padding: 0;
  border: none;
}

.checklist-table td {
  padding: 12px;
  text-align: left;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.checklist {
  list-style: none;
  padding: 0;
}

.checklist li {
  margin-bottom: 20px;
}

.step-progress {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}


.checkmark {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background-color: #ccc;
  cursor: pointer;
}

.checkmark.completed {
  background-color: #00C853;
}

.checkmark-bar {
  width: 10px;
  height: 4px;
  background-color: #fff;
  transition: transform 0.3s ease-in-out;
}

.checkmark.completed .checkmark-bar {
  background-color: #fff;
  transform: scaleX(1);
}

.step-label {
  margin-left: 10px;
}

.progress-bar {
  flex: 1;
  height: 8px;
  background-color: #ccc;
  border-radius: 4px;
  position: relative;
}

.progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 4px;
  background-color: #00C853;
  transition: width 0.3s ease-in-out;
}

.completed .progress {
  background-color: #00C853;
}

.step-done {
  color: #00C853;
}

.step-title {
  margin-left: 10px;
  margin-right: 10px;
}

.animated {
  animation: congratulations 3s linear;
  animation-iteration-count: 1;
}

.congratulatory-note {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background-color: #00C853;
  color: black;
  text-align: center;
  opacity: 1;
  transition: opacity 0.5s ease;
}

.success-toast {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background-color: #13cc19;
  color: black;
  text-align: center;
  opacity: 1;
  transition: opacity 0.5s ease;
}

.congratulatory-note.hidden {
  opacity: 0;
}

@keyframes congratulations {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  75% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>
