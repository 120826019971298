<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <div class="pl-2">
    <v-chip small>
      <v-icon small :color="severityColor" left>mdi-thermometer-lines</v-icon>
      {{ severityName }}
    </v-chip>
  </div>
</template>

<script>
export default {
  name: "IncidentSeverity",

  props: {
    severityName: {
      type: String,
      required: true,
    },
    severityColor: {
      type: String,
      required: true,
    },
  },
}
</script>
