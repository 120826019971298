<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2024 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
    <v-container fluid>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title class="pb-0 mb-0">
            Activity Graph
            </v-card-title>
            <v-sheet
              color="transparent"
              rounded="lg"
              >
              <v-sparkline
                :value="value"
                :gradient="['#0D47A1', '#00BCD4', '#CDDC39']"
                color="white"
                line-width="2"
                height="60"
                padding="15"
                type="bar"
                auto-draw
                :auto-draw-duration="750"
              ></v-sparkline>
            </v-sheet>
            <v-card-text class="pt-1">
              <div class="text-caption grey--text font-weight-light">
                Incident started:
                {{ formatDate(incident.created_at) }}
                &mdash;
                Ended:
                {{ formatDate(incident.closed_at) || "Ongoing" }}
              </div>
              <v-data-table
                :items="events"
                hide-default-footer
              >
              </v-data-table>
              <v-divider class="my-2"></v-divider>
              <v-icon class="mr-2" small> mdi-clock-outline </v-icon>
              <span class="text-caption grey--text font-weight-light">Last event {{ lastEvent.ended_at | formatRelativeDate }}</span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>

  <script>
  import { mapFields } from "vuex-map-fields";

  export default {
    name: "IncidentEventGraph",

    data() {
      return {
        labels: [],
        value: []
      };
    },

    computed: {
      lastEvent() {
        return this.events[this.events.length - 1];
      },
    },

    props: {
      events: {
        type: Array,
        required: true,
      },
      incident: {
        type: Object,
        required: true,
      },
    },

    mounted() {
      // Group events by minute and count the events for each minute
      const eventsByMinute = this.events.reduce((acc, event) => {
        const date = new Date(event.started_at);
        const minute = date.getMinutes();
        const formattedMinute = `${minute < 10 ? '0' : ''}${minute}`; // Format minute as 'MM:00'
        acc[formattedMinute] = (acc[formattedMinute] || 0) + 1;
        return acc;
      }, {});

      // Extract labels (minutes) and values (event counts) from the grouped data
      const earliestDate = new Date(
        Math.min(...this.events.map(event => new Date(event.started_at)))
      );
      const latestDate = new Date(
        Math.max(...this.events.map(event => new Date(event.started_at)))
      );

      // Format the labels with the start and end dates
      const startDateLabel = this.formatDate(earliestDate);
      const endDateLabel = this.formatDate(latestDate);

      this.labels = Object.values(eventsByMinute);
      this.value = Object.values(eventsByMinute);

      // Temp fix for sparkline not rendering when there is only one data point...For the first occurrence. TODO: Fix this
      if (this.labels.length < 2) {
        this.labels = [1, 1];
        this.value = [1, 1];
      }
    },

    methods: {
      formatDate(dateString) {
        if (!dateString) {
          return "";
        }
        const date = new Date(dateString);
        return date.toLocaleString('en-US', {
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        });
      },
    },
  };
  </script>

  <style scoped>
  /* Add any custom styling here */
  </style>
