<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container fluid>
  <div>
    <div v-if="tasks && tasks.length">
      <v-row class="ml-6 mr-6">
        <v-col v-for="task in tasks" :key="task.id" cols="12" sm="6" md="6">
          <v-card class="pa-3" outlined>
            <v-card-title class="d-flex justify-space-between mb-3">
              <div class="mb-2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-switch
                        v-model="taskStatusModels[task.id]"
                        :color="getTaskStatusColor(task)"
                        hide-details
                        @change="toggleTaskStatus(task)"
                        inset
                        :loading="taskLoading[task.id]"
                      ></v-switch>
                    </div>
                  </template>
                  <span>Toggle to mark this task as completed or pending.</span>
                </v-tooltip>
              </div>
              <v-chip outline v-if="task.resolve_by && task.status !== 'Resolved'" class="mr-2 mb-4">
                <v-icon :color="getTaskStatusColorClass(task)" left>mdi-timer-outline</v-icon>
                Resolve By: {{ task.resolve_by | formatRelativeDate }}
              </v-chip>
              <span>
                <v-icon left>mdi-axis-arrow-info</v-icon>
                {{ task.description }}
                <v-btn :href="task.weblink" target="_blank" icon>
                  <v-icon color="primary">mdi-open-in-new</v-icon>
                </v-btn>
              </span>
            </v-card-title>

            <v-card-subtitle class="mt-3 mb-3">
              <v-chip dark :color="getTaskStatusColorClass(task)" class="mr-2">
                <div v-if="task.status === 'Resolved'">
                  <v-icon left>mdi-check-circle</v-icon>
                  {{ task.status }}
                </div>
                <div v-else>
                  <v-icon left>mdi-circle</v-icon>
                  {{ task.status }}
                </div>
              </v-chip>
            </v-card-subtitle>

            <v-card-text>
              <div>
                <strong>
                  <v-icon small left>mdi-calendar-clock</v-icon>
                  Created:&nbsp;</strong>{{ task.created_at | formatRelativeDate }}
              </div>
              <div>
                <strong>
                  <v-icon small left>mdi-list-status</v-icon>
                  Status:&nbsp;</strong>
                <v-icon small :color="getTaskStatusColorClass(task)">mdi-circle</v-icon>
                {{ task.status }}
              </div>
              <div v-if="task.resolve_by">
                <strong>
                  <v-icon small left>mdi-source-branch</v-icon>
                  Resolve By:&nbsp;</strong>{{ task.resolve_by | formatRelativeDate }}
              </div>
              <div>
                <strong>
                  <v-icon small left>mdi-account-multiple</v-icon>
                  Assignees:&nbsp;</strong>{{ task.assignees | individualNames }}
              </div>
            </v-card-text>

            <v-card-actions>
              <v-progress-circular
                v-if="taskLoading[task.id]"
                indeterminate
                size="24"
                color="primary"
              ></v-progress-circular>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <p class="text-center">No tasks have been created for this business activity object (IAO).</p>
    </div>
  </div>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import TaskApi from "@/task/api"

export default {
  name: "IncidentTasksTab",

  computed: {
    ...mapFields("incident", [
      "selected.tasks",
      "selected.id",
      "selected.title",
      "selected.description",
      "selected.incident_priority",
      "selected.incident_type",
      "selected.incident_severity",
      "selected.project",
    ]
    ),
  },

  data() {
    return {
      taskStatusModels: {},
      taskLoading: {},
    }
  },

  watch: {
    tasks: {
      immediate: true,
      handler(tasks) {
        tasks.forEach(task => {
          this.$set(this.taskStatusModels, task.id, task.status === 'Resolved');
        });
      }
    }
  },

  methods: {
    getTaskStatusColor(task) {
      const dueDate = task.resolve_by ? new Date(task.resolve_by) : null;
      const currentDate = new Date();
      if (dueDate && currentDate > dueDate && task.status !== 'Resolved') {
        return "error";
      } else {
          return "success";
      }
    },
    getTaskStatusIcon(task) {
      const dueDate = task.resolve_by ? new Date(task.resolve_by) : null;
      const currentDate = new Date();
      if (dueDate && currentDate > dueDate && task.status !== 'Resolved') {
        return "mdi-alert-circle";
      } else {
          return "mdi-check-circle";
      }
    },
    getTaskStatusColorClass(task) {
      const dueDate = task.resolve_by ? new Date(task.resolve_by) : null;
      const currentDate = new Date();
      if (task.status !== 'Resolved' && dueDate && currentDate > dueDate) {
          return "error";
      } else if (task.status === 'Resolved') {
          return "success";
      } else {
          return "primary";
      }
    },
    async toggleTaskStatus(task) {
      this.taskLoading[task.id] = true;
      const newStatus = task.status === 'Resolved' ? 'Open' : 'Resolved';
      const payload = {
        status: newStatus,
        // Hacky. KJ>.
        incident: {
          id: this.id,
          title: this.title,
          description: this.description,
          incident_priority: this.incident_priority,
          incident_type: this.incident_type,
          incident_severity: this.incident_severity,
          project: this.project,
        }
      };
      try {
        await TaskApi.update(task.id, payload);
        this.$store.commit('incident/UPDATE_TASK_STATUS', { taskId: task.id, newStatus: newStatus });
      } catch (error) {
        console.error("Failed to update the task status:", error);
      }
      finally {
        this.taskLoading[task.id] = false;
      }
    },
    isTaskGreen(task) {
      return this.getTaskStatusColor(task) === "success";
    },
  },
}
</script>

<style scoped>
.align-center {
  align-items: center;
}
.mx-1 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.pending {
  background-color: rgb(0, 187, 255);
}
.completed {
  background-color:  #4CAF50;
}
.overdue {
  background-color: red;
}
