<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2024 Kishore Jalleda <
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
    <v-container fluid>
      <v-row justify="center">
        <date-chip-group-relative
          class="mt-6"
          label="Time Range"
          v-model="selectedDateTime"
          @input="onSelectedDateTimeChange"
        />
      </v-row>
      <v-row class="ml-6 mr-6 justify-center">
        <v-col class="pa-1" cols="6">
        <incident-event-graph
          :incident="selected"
          :events="selected.events"
        />
        </v-col>
      </v-row>
      <v-row v-if="participants && participants.length >= 1" class="ml-6 mr-6">
        <v-col class="pa-1" v-for="participant in participants" :key="participant.id" cols="12" sm="6" md="6">
          <incident-participant-card :participant="participant" :count="participant.count" :selectedDateTime="selectedDateTime" />
        </v-col>
      </v-row>
      <div v-else>
        <p class="text-center">No participant data available.</p>
      </div>
    </v-container>
  </template>

  <script>
  import IncidentParticipantCard from "@/incident/participant/IncidentParticipantCard.vue"
  import DateChipGroupRelative from "@/components/DateChipGroupRelative.vue"
  import IncidentEventGraph from "@/event/IncidentEventGraph.vue"

  export default {
    name: "IncidentParticipantsTab",
    components: {
      IncidentParticipantCard,
      DateChipGroupRelative,
      IncidentEventGraph,
    },
    props: {
      selected: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        selectedDateTime: 30,
      }
    },
    computed: {
      participants() {
        return this.selected.participants
      },
      project() {
        return this.selected.project
      },
    },
    methods: {
      onSelectedDateTimeChange(newValue) {
        this.selectedDateTime = newValue
      },
    },
  }
  </script>
