<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container fluid>
    <v-row class="ml-6 mr-6">
      <v-col
        cols="12"
        md="6"
        lg="4"
        v-for="item in value"
        :key="item.id"
      >
        <v-card outlined class="mb-5">
          <v-card-title>
            <v-icon left>mdi-cog-transfer-outline</v-icon>
            {{ item.workflow.name }}
          </v-card-title>
          <v-card-subtitle>
            <div>
              <v-chip small :color="getStatusChipColor(item.status)" class="mt-2 mb-2" :outline="item.status !== 'Active'">
              {{ item.status }}
            </v-chip>
            <v-icon left :color="getStatusChipColor(item.status)">
              {{ getStatusIcon(item.status) }}
            </v-icon>
            </div>
            <div>
              <v-icon small>mdi-account-arrow-right-outline</v-icon>
              <strong>Creator: </strong>
              <template v-if="item.creator && item.creator.individual">
                <individual :individual="item.creator.individual" />
              </template>
              <template v-else>
                Unknown
              </template>
            </div>
          </v-card-subtitle>
          <v-card-text>
            <div class="md-3">
              <strong>Run Reason: </strong>
              {{ item.run_reason }}
            </div>
            <div class="mt-2">
              <v-icon small left>mdi-calendar</v-icon>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    {{ item.created_at | formatRelativeDate }}
                  </span>
                </template>
                <span>{{ item.created_at | formatDate }}</span>
              </v-tooltip>
            </div>
            <div class="mt-2 mb-2">
              <v-icon small left>mdi-folder-plus-outline</v-icon>
              <strong>Workflow Artifacts: </strong>
            </div>
            <div v-for="artifact in item.artifacts" :key="artifact.resource_id">
              <v-icon small left>mdi-link-variant</v-icon>
              <a :href="artifact.weblink" target="_blank">{{ artifact.name }}</a>
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <workflow-instance-detail-menu :value="item" />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import WorkflowInstanceDetailMenu from '@/workflow/WorkflowInstanceDetailMenu.vue';
import Individual from '@/individual/Individual.vue';


export default {
  name: 'WorkflowInstanceTab',
  components: {
    WorkflowInstanceDetailMenu,
    Individual,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getStatusChipColor(status) {
      switch (status) {
        case 'Submitted':
          return 'blue';
        case 'Created':
          return 'light-blue';
        case 'Running':
          return 'success';
        case 'Completed':
          return 'success';
        case 'Failed':
          return 'red';
        default:
          return 'grey';
      }
    },
    getStatusIcon(status) {
      switch (status) {
        case 'Submitted':
          return 'mdi-upload';
        case 'Created':
          return 'mdi-file-document-edit-outline';
        case 'Running':
          return 'mdi-play-circle';
        case 'Completed':
          return 'mdi-check-circle';
        case 'Failed':
          return 'mdi-alert-circle';
        default:
          return 'mdi-help-circle';
      }
    },
  },
};
</script>
