<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-dialog v-model="showRun" persistent max-width="800px">
    <v-card>
      <v-card-title class="primary white--text">
        <v-icon large left>mdi-play-circle-outline</v-icon>
        <span class="headline">Run Workflow</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row dense>
            <v-col cols="12" v-if="id">
              <v-card outlined elevation="0" class="pa-3">
                <v-list-item two-line>
                  <v-list-item-avatar>
                    <v-icon class="mr-1">mdi-axis-arrow-info</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="text-h6">
                      Workflow Details</v-list-item-title>
                    <v-list-item-subtitle>{{ workflow.name }} - {{ workflow.resource_id }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list class="transparent py-0">
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="success">mdi-check-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Status</v-list-item-title>
                      <v-list-item-subtitle>{{ status }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-for="artifact in artifacts" :key="artifact.id">
                    <v-list-item-icon>
                      <v-icon color="warning">mdi-package-variant-closed</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ artifact.name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
            <v-col cols="12" v-else>
              <workflow-select v-model="workflow" />
              <v-fade-transition>
                <div v-if="workflow.id">
                  <workflow-parameters-input v-model="parameters" />
                  <v-textarea
                    v-model="run_reason"
                    label="Run Reason"
                    hint="Short note about why workflow is being run."
                    clearable
                    outlined
                    dense
                  ></v-textarea>
                </div>
              </v-fade-transition>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-spacer></v-spacer>
        <v-btn icon @click="closeRun()">
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
        <v-btn color="success" dark :loading="loading" @click="run()">
          <v-icon left>mdi-play</v-icon>Run
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex"
import { mapFields } from "vuex-map-fields"
import WorkflowSelect from "@/workflow/WorkflowSelect.vue"
import WorkflowParametersInput from "@/workflow/WorkflowParametersInput.vue"
export default {
  name: "WorkflowRunModal",
  props: {
    value: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },

  data() {
    return {}
  },

  components: {
    WorkflowSelect,
    WorkflowParametersInput,
  },
  computed: {
    ...mapFields("workflow", [
      "dialogs.showRun",
      "selected",
      "selectedInstance.case",
      "selectedInstance.incident",
      "selectedInstance.signal",
      "selectedInstance.id",
      "selectedInstance.status",
      "selectedInstance.loading",
      "selectedInstance.workflow",
      "selectedInstance.parameters",
      "selectedInstance.artifacts",
      "selectedInstance.run_reason",
    ]),
  },

  methods: {
    ...mapActions("workflow", ["closeRun", "run"]),
  },

  created() {
    this.$watch(
      (vm) => [vm.workflow],
      () => {
        // create a copy of the workflow params
        this.parameters = this.workflow.parameters
      }
    )
  },
}
</script>
<style scoped>
.v-card-title {
  align-items: center;
  justify-content: start;
}

.v-list-item-avatar {
  color: var(--v-primary-base);
}

.v-list-item-icon {
  color: var(--v-icon-color);
}

.workflow-details {
  margin-bottom: 20px;
}
</style>
